import React from 'react';
import {Box, Typography} from '@mui/material';
import {Tooltip} from './Tooltip';

type Props = {
  showTooltip?: boolean;
};

export const ReadingRateSubtitle: React.FC<Props> = ({showTooltip = true}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '6px',
      marginBottom: '12px',
    }}>
    <Typography variant="body1" sx={{lineHeight: '1.6'}}>
      Words per Minute
    </Typography>
    {showTooltip && <Tooltip />}
  </Box>
);
