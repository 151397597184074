import React from 'react';
import {BoxWhiskerPlot} from './BoxWhiskerPlot';
import {ReadingRateSubtitle} from './ReadingRate.subtitle';
import {CardBodyTypography} from '../components/ProgressCard/CardBodyTypography';
import {Loader} from '../components/Loader';
import {QueryResult, ReadingRateData} from '../types';
import {ReadingRateCopy} from './ReadingRate.copy';
import {BoxWhiskerEmpty} from './BoxWhisker.empty';

type Props = {
  profileName: string;
  profileId: string;
  readingRate: QueryResult<ReadingRateData>;
};

export const ReadingRateContent: React.FC<Props> = ({
  profileName,
  profileId,
  readingRate,
}) => {
  const {data, error, loading} = readingRate;

  if (loading) return <Loader />;
  if (error || !data) return null;

  const hasInsufficientReadingData =
    data.digitalBooksRead < 2 && data.minutes < 5;

  return (
    <>
      {hasInsufficientReadingData ? (
        <BoxWhiskerEmpty />
      ) : (
        <BoxWhiskerPlot
          wpm={data?.wpm || 0}
          minWpm={data?.minWpm || 0}
          maxWpm={data?.maxWpm || 0}
          lowerQuartileWpm={data?.lowerQuartileWpm || 0}
          upperQuartileWpm={data?.upperQuartileWpm || 0}
          defaultLowWpm={data?.defaultLowWpm || 0}
          defaultHighWpm={data?.defaultHighWpm || 0}
        />
      )}
      <ReadingRateSubtitle showTooltip={!hasInsufficientReadingData} />
      <CardBodyTypography>
        <ReadingRateCopy
          data={data}
          profileId={profileId}
          profileName={profileName}
          hasInsufficientReadingData={hasInsufficientReadingData}
        />
      </CardBodyTypography>
    </>
  );
};
