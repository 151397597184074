import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import {gray700, gray900} from 'shared/styles/palette';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';

const styles = (theme: Theme) =>
  createStyles({
    item: {
      marginBottom: theme.spacing(3),
    },
    term: {
      fontSize: '1.25rem',
      fontWeight: 500,
      color: gray700,
      lineHeight: '1.4',
      letterSpacing: '-0.112px',
      [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
        fontSize: `.875rem`,
      },
    },
    desc: {
      margin: 0,
      fontSize: '1.25rem',
      color: gray900,
      lineHeight: '1.4',
      letterSpacing: '-0.128px',
      [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
        fontSize: `.875rem`,
      },
    },
  });

export default styles;
