import React from 'react';
import {MobileEmptyState} from './MobileEmptyState';
import {DesktopEmptyState} from './DesktopEmptyState';
import {Box} from '@mui/material';
import {NoQuestsBanner} from './Banner.no-quests';
import {DataSyncBanner} from './Banner.data-sync';

type Props = {
  isMobileView: boolean;
  profileName: string;
  hasQuests: boolean;
  showReadingRate?: boolean;
};

export const EmptyState: React.FC<Props> = ({
  isMobileView,
  profileName,
  hasQuests,
  showReadingRate,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
      }}>
      {!hasQuests ? (
        <NoQuestsBanner profileName={profileName} />
      ) : (
        <DataSyncBanner profileName={profileName} />
      )}

      {isMobileView ? (
        <MobileEmptyState
          profileName={profileName}
          showReadingRate={showReadingRate}
        />
      ) : (
        <DesktopEmptyState
          profileName={profileName}
          showReadingRate={showReadingRate}
        />
      )}
    </Box>
  );
};
