import React, {forwardRef} from 'react';
import {Box} from '@mui/material';
import {PdfHeader} from './PdfHeader';

type Props = {
  profileName: string;
  children: React.ReactNode;
};

export const PdfReportView = forwardRef<HTMLDivElement, Props>(
  ({profileName, children}, ref) => (
    <Box
      ref={ref}
      sx={{
        position: 'absolute',
        top: '-9999px',
        left: '-9999px',
      }}>
      <PdfHeader profileName={profileName} />
      {children}
    </Box>
  ),
);
