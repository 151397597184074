import isMobilePhone from 'validator/lib/isMobilePhone';

export type CountryType = {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
};

export const defaultCountry = {
  code: 'US',
  label: 'United States',
  phone: '1',
};

export const fallbackCountries: CountryType[] = [
  {code: 'PH', label: 'Philippines', phone: '63'},
];

export function phoneNumberRegex(country: CountryType) {
  return /^\d{10}$/;
}

export function isValidNumber(phoneNumber: string): boolean {
  return isMobilePhone(phoneNumber, 'any', {
    strictMode: true,
  });
}

export function getCountries(
  countries: CountryType[] | undefined,
): CountryType[] {
  const expansionCountries: CountryType[] = countries?.length
    ? countries
    : fallbackCountries;
  const sortedCountries = expansionCountries.sort((a, b) => {
    return a.label.localeCompare(b.label);
  });

  return [defaultCountry, ...sortedCountries];
}
