import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import featureFlags from 'shared/utils/featureFlags';
import {useAuth} from 'shared/utils/providers/authProvider';
import ErrorBoundary from './ErrorBoundary';
import {PRIVATE_ROUTES, PUBLIC_ROUTES} from './routes';

/**
 * Use for publicly accessible routes (e.g. pages not behind a login).
 * Redirects a user to /account page if they are already logged in.
 */
export const PageRoute = ({render, ...props}) => {
  const auth = useAuth();

  return (
    <>
      {auth.user ? (
        <Redirect
          to={{
            pathname: PRIVATE_ROUTES.ACCOUNT.path,
          }}
        />
      ) : (
        <Route
          {...props}
          render={renderProps => (
            <ErrorBoundary {...renderProps}>
              {render(renderProps)}
            </ErrorBoundary>
          )}
        />
      )}
    </>
  );
};

/**
 * Custom route for private pages.
 * Redirects user to login page if they are not logged in yet
 * or a 404 if they try to access a page that's hidden behind a feature flag.
 */
export const PrivateRoute = ({render, featureFlagIds = [], ...rest}) => {
  const auth = useAuth();
  const isAllFeaturesEnabled = featureFlagIds
    .map(id => featureFlags.isOn(id))
    .every(Boolean);

  if (auth.user && featureFlagIds.length && !isAllFeaturesEnabled) {
    return (
      <Redirect
        to={{
          pathname: PUBLIC_ROUTES.NOT_FOUND,
          state: {
            homepageLink: PRIVATE_ROUTES.ACCOUNT.path,
          },
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={renderProps =>
        auth.user ? (
          <ErrorBoundary {...renderProps}>
            {render(renderProps.match)}
          </ErrorBoundary>
        ) : (
          <Redirect
            to={{
              pathname: PUBLIC_ROUTES.ROOT,
              state: {from: renderProps.location},
            }}
          />
        )
      }
    />
  );
};
