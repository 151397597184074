import React from 'react';
import {Box, SxProps, Theme, Typography} from '@mui/material';
import {turquoise900} from 'shared/styles/palette';

type Props = {
  position?: string;
  value: number | string;
  marker: React.ReactNode;
  sx?: SxProps<Theme>;
};

export const Marker: React.FC<Props> = ({
  position = '50%',
  value,
  marker,
  sx,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        bottom: '0',
        left: position,
        transform: 'translateX(-50%)',
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '32px',
          height: '28px',
        }}>
        <Typography
          sx={{
            fontSize: '26px',
            fontWeight: '800',
            lineHeight: '1.4',
            letterSpacing: '-0.104px',
            color: turquoise900,
            ...sx,
          }}>
          {value}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'end',
          width: '20px',
          height: '39px',
        }}>
        {marker}
      </Box>
    </Box>
  );
};
