export enum ScreenState {
  RequestAdjustment = 'requestAdjustment',
  RequestReceived = 'requestReceived',
}

export enum AdjustmentRequest {
  IncreaseDifficulty = 'Increase Difficulty',
  DecreaseDifficulty = 'Decrease Difficulty',
}

export type LevelSentence = {
  level: string;
  sentence: string;
};
