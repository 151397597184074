import React from 'react';
import {CardBody} from './CardBody';
import {Box, Card, CardContent, useTheme} from '@mui/material';
import {gray100, orange50} from 'shared/styles/palette';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';
import {ReactComponent as CreateStoryCardIcon} from 'shared/assets/icons/CreateStoryCardICon.svg';
import {CTA} from './CTA';
import {buildPersonalizedStoryUrl} from '../CreateStoryScreen.service';

type Props = {
  email: string;
  onCtaClick?: () => void;
};

export const CreateStoryCard: React.FC<Props> = ({email, onCtaClick}) => {
  const theme = useTheme();
  const personalizedStoryUrl = buildPersonalizedStoryUrl(email);

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        margin: '0 auto',
        marginBottom: '24px',
        padding: '24px',
        borderRadius: '16px',
        border: `1px solid ${gray100}`,
        backgroundColor: orange50,
        boxShadow: '4px 8px 20px 0px rgba(0, 0, 0, 0.05)',
        [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
          paddingBottom: '32px',
        },
      }}>
      <Box
        display="flex"
        justifyContent="center"
        sx={{
          mb: '12px',
          [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
            mb: '20px',
          },
        }}>
        <CreateStoryCardIcon />
      </Box>

      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          gap: '20px',
          padding: 0,
        }}>
        <CardBody
          title="Create a customized story"
          description="Use this link to create a free, custom story that your child can read in the app!"
        />
        <CTA
          label="Let's Go!"
          onClick={onCtaClick}
          href={personalizedStoryUrl}
        />
      </CardContent>
    </Card>
  );
};
