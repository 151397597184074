import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';

import Section from '../../../components/Section';
import ProfileCard from './ProfileScreen.profile-card';
import styles from './ProfilesScreen.styles';
import Header from './ProfilesScreen.header';
import MaxProfilesModal from './ProfilesScreen.max-profiles.modal';
import {useProfiles} from './EditProfile/EditProfileScreen.service';
const ProfilesScreen = ({classes}: WithStyles<typeof styles>) => {
  const {loading, data, error} = useProfiles();

  const customerHasProfiles = data?.profiles?.length;

  const [openModal, setOpenModal] = React.useState(false);
  const onCloseModal = () => {
    setOpenModal(false);
  };

  const openMaxProfilesModal = () => {
    setOpenModal(true);
  };

  return (
    <Section
      titleSection={
        <Header profiles={data?.profiles} openModal={openMaxProfilesModal} />
      }
      loading={loading}
      error={error ? 'Error loading profiles' : ''}>
      <Box className={classes.profilesScreenContainer}>
        {customerHasProfiles ? (
          data?.profiles?.map(
            ({
              id,
              name,
              readingLevel,
              gradeAndMonth,
              profileCharacter,
              requestedLevelAdjustmentAt,
            }) => (
              <ProfileCard
                key={id}
                name={name}
                readingLevel={gradeAndMonth || readingLevel}
                id={id}
                profileCharacter={profileCharacter}
                requestedLevelAdjustmentAt={requestedLevelAdjustmentAt}
              />
            ),
          )
        ) : (
          <Typography className={classes.header}>
            No Profiles Assigned to the Account
          </Typography>
        )}
      </Box>
      <MaxProfilesModal open={openModal} onCloseModal={onCloseModal} />
    </Section>
  );
};

export default withStyles(styles)(ProfilesScreen);
