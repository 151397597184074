import {gql} from '@apollo/client';

export const externalBookQuery = gql`
  query ExternalBook($id: String!) {
    externalBook(id: $id) {
      id
      author
      coverPhotoURL
      title
      pages {
        pagePhotoURL
        content
      }
    }
  }
`;

export const externalBooksQuery = gql`
  query ExternalBooks($pagination: PaginationArgs!, $filter: BookFilterArgs) {
    externalBooks(pagination: $pagination, filter: $filter) {
      books {
        id
        author
        coverPhotoURL
        title
      }
      pageInfo {
        page
        totalPages
        totalCount
        cursor
      }
    }
  }
`;
