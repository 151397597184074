export enum CancellationFormState {
  SelectReasonCategory = 'selectReasonCategory',
  SelectReasonDetail = 'selectReasonDetail',
  CancellationOffer = 'cancellationOffer',
  FeedbackForm = 'feedbackForm',
  CancellationConfirmation = 'cancellationConfirmation',
  CancellationConfirmed = 'cancellationConfirmed',
  CustomizedCancellation = 'customizedCancellation',
  CancellationOfferMonthly = 'cancelationOfferMonthly',
  CancellationSwitchedMonthly = 'cancellationSwitchedMonthly',
}

export type CancellationInput = {
  cancelCategory: string;
  cancelDetail: string;
  feedback: string;
};
