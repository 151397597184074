export const CustomerTags = {
  questsEnabled: 'quests-enabled',
  questsV2Enabled: 'quests-v2-enabled',
};

const questTags = [CustomerTags.questsEnabled, CustomerTags.questsV2Enabled];

export function isQuestsEnabled(tags?: string[]): boolean {
  return tags?.some(tag => questTags.includes(tag)) ?? false;
}
