import {Box, Grid, Typography} from '@mui/material';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import Section from 'customerPortal/components/Section';
import useMobileLayout from 'customerPortal/utils/useMobileLayout';
import React from 'react';
import Button from 'shared/components/common/Button';
import styles from './CancellationSwitchedMonthly.styles';
import {ReactComponent as SwitchedToMonthlyPlanSVG} from 'shared/assets/switchedToMonthlyPlan.svg';

interface Props extends WithStyles<typeof styles> {
  endDate?: string;
  onConfirm: () => Promise<void>;
}

const CancellationSwitchedMonthly = ({classes, onConfirm, endDate}: Props) => {
  const isMobile = useMobileLayout();

  return (
    <>
      <Grid container className={classes.content}>
        <Box className={classes.topContainer}>
          {isMobile && <Section title="Cancellation" />}

          <Box>
            <SwitchedToMonthlyPlanSVG className={classes.icon} />
          </Box>

          <Typography variant={'h6'} align={'center'}>
            Click Confirm below to be switched to our monthly plan
          </Typography>

          <Typography
            variant={'body1'}
            align={'center'}
            className={classes.body}>
            You will be charged $14.99 each month after your trial ends on{' '}
            {endDate}. You can cancel anytyme from your settings page
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" width="100%">
          <Grid
            container
            justifyContent="center"
            className={classes.buttonContainer}>
            <Grid item>
              <Button className={clsx(classes.button)} onClick={onConfirm}>
                Confirm Change
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default withStyles(styles)(CancellationSwitchedMonthly);
