import React, {useRef, useEffect} from 'react';
import {Box, SxProps, Theme} from '@mui/material';
import {ReactComponent as Whisker} from 'shared/assets/whisker.svg';
import {yellow400} from 'shared/styles/palette';

type Props = {
  boxWidth?: number;
  setWhiskerWidth?: (width: number) => void;
  sx?: SxProps<Theme>;
};

export const BoxWhisker: React.FC<Props> = ({
  boxWidth = 154.587,
  setWhiskerWidth,
  sx,
}) => {
  const whiskerRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (setWhiskerWidth && whiskerRef.current) {
      setWhiskerWidth(whiskerRef.current.getBoundingClientRect().width);
    }
  }, [setWhiskerWidth]);

  return (
    <>
      <Whisker ref={whiskerRef} />
      <Box
        sx={{
          position: 'absolute',
          width: `${boxWidth}px`,
          height: '25.694px',
          backgroundColor: yellow400,
          borderRadius: '16px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          ...sx,
        }}
      />
    </>
  );
};
