import React from 'react';
import {Button} from '@mui/material';
import {ReactComponent as DeleteIcon} from 'shared/assets/icons/DeleteIcon.svg';
import {gray500} from 'shared/styles/palette';

type Props = {
  onClick: () => void;
};

const DeleteAccountButton: React.FC<Props> = ({onClick}) => {
  return (
    <Button
      onClick={onClick}
      startIcon={<DeleteIcon />}
      sx={{
        color: gray500,
        fontSize: '16px',
        fontWeight: 700,
        textTransform: 'none',
        letterSpacing: '-0.048px',
        padding: '8px 16px',
        '&:hover': {
          background: 'transparent',
          opacity: 0.8,
        },
      }}>
      Delete Account
    </Button>
  );
};

export default DeleteAccountButton;
