import React from 'react';
import {Box, Typography, Divider, IconButton} from '@mui/material';
import {ReactComponent as ChevronDown} from 'shared/assets/icons/chevronDown.svg';
import {ReactComponent as PaperPlane} from 'shared/assets/icons/paperPlaneIcon.svg';
import {blue500, gray300} from 'shared/styles/palette';
import {Profile} from 'shared/types/profile';
import {
  deviceType,
  getProfileCharacter,
} from '../../ProgressReportScreen.service';
import {ProfileCharacter} from '../../types';

type Props = {
  selectedProfile: Profile | null;
  isOpen: boolean;
  hasMultipleProfiles: boolean;
  toggleDropdown: () => void;
  onShareClick: () => void;
  reportsSharingEnabled: boolean;
};

export const ProfileDropdownHeader: React.FC<Props> = ({
  selectedProfile,
  isOpen,
  hasMultipleProfiles,
  toggleDropdown,
  onShareClick,
  reportsSharingEnabled,
}) => {
  const ProfileCharacterImage = getProfileCharacter(
    selectedProfile?.profileCharacter as ProfileCharacter,
  );

  const handleShareClick = () => {
    const device = deviceType();
    window.analytics.track('ParentDashboard HeaderShareButtonClicked', {
      device,
    });

    onShareClick();
  };

  return (
    <Box sx={{px: '24px'}}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          userSelect: 'none',
          paddingBottom: '16px',
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
            cursor: 'pointer',
            flexGrow: 1,
          }}
          onClick={toggleDropdown}>
          <ProfileCharacterImage />
          <Typography
            sx={{
              fontSize: '22px',
              fontWeight: '700',
              textTransform: 'capitalize',
              lineHeight: '1.4',
              letterSpacing: '-0.176px',
              color: blue500,
            }}>
            {selectedProfile?.name}
          </Typography>
          {hasMultipleProfiles && (
            <Box
              component="span"
              sx={{
                transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s ease',
              }}>
              <ChevronDown />
            </Box>
          )}
        </Box>
        {reportsSharingEnabled && (
          <IconButton
            onClick={handleShareClick}
            sx={{
              mr: '8px',
              padding: 0,
            }}>
            <PaperPlane />
          </IconButton>
        )}
      </Box>
      <Divider
        sx={{
          backgroundColor: gray300,
          height: '1px',
        }}
      />
    </Box>
  );
};
