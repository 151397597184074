import React from 'react';
import {CurriculumProgress} from '../types';
import {Link} from '../components/Link';
import AnalyticsService from 'analytics/Analytics.service';
import {pluralize} from '../ProgressReportScreen.service';

type Props = {
  profileId: string;
  profileName: string;
  data: CurriculumProgress | null;
};

export const ReadingLevelCopy: React.FC<Props> = ({
  profileName,
  data,
  profileId,
}) => {
  if (!data) {
    return (
      <>
        {profileName} started a new curriculum! To see their progress, encourage
        them to complete a quest.
      </>
    );
  }

  const {
    gradeName,
    remainingQuests,
    subGrade,
    nextSubGrade,
    progress,
    monthEquivalent,
  } = data || {};

  const questsText = pluralize(
    remainingQuests,
    'reading quest',
    'reading quests',
  );

  const adjustmentLink = (
    <>
      If {profileName}'s books are feeling too hard or too easy, you can adjust
      their level{' '}
      <Link
        text="here."
        onClick={() =>
          AnalyticsService.trackDashboardLevelAdjustmentClicked(
            'Reading level card',
          )
        }
        to={`/account/profiles/${profileId}/adjustReadingLevel`}
      />
    </>
  );

  if (!nextSubGrade) {
    return progress < 100 ? (
      <>
        {profileName} is reading at the level of a child in the{' '}
        {monthEquivalent} month of {gradeName}. They are just {questsText} away
        from completing our {subGrade} curriculum. {adjustmentLink}
      </>
    ) : (
      <>
        Congrats! {profileName} has finished the {subGrade} curriculum!
      </>
    );
  }

  return progress === 100 ? (
    <>
      Congrats! {profileName} has finished the {subGrade} curriculum! Complete
      the next quest to advance to the {nextSubGrade} curriculum.{' '}
      {adjustmentLink}
    </>
  ) : (
    <>
      {profileName} is reading at the level of a child in the {monthEquivalent}{' '}
      month of {gradeName}. They are just {questsText} away from completing our{' '}
      {subGrade} curriculum and moving on to the {nextSubGrade} curriculum.{' '}
      {adjustmentLink}
    </>
  );
};
