import withStyles from '@mui/styles/withStyles';
import React from 'react';
import {NavLink} from 'react-router-dom';
import {WithStyles} from '@mui/styles';
import clsx from 'clsx';
import styles from './DesktopMenu.styles';
import {Navigation} from 'customerPortal/constants/navigationItems';
import Card from 'customerPortal/components/Card/Card';
import ReturnByDateChip from 'customerPortal/components/ReturnByDateChip/ReturnByDateChip';
import {Box} from '@mui/material';
interface Props extends WithStyles<typeof styles> {
  navigationItems: Navigation[];
  containerClass?: string;
  chipContents?: string;
}

const DesktopMenu = ({
  classes,
  containerClass,
  navigationItems,
  chipContents,
}: Props) => {
  return (
    <Card className={containerClass}>
      <ul className={classes.navList}>
        {navigationItems.map((item: Navigation, index: number) => {
          if (!item.isEnabled()) return null;

          return (
            <li key={index} className={classes.linkContainer}>
              <NavLink
                to={{
                  pathname: `${item.to}`,
                }}
                className={clsx(
                  classes.navigationLink,
                  item.isHighlighted && classes.highlightedLink,
                )}
                activeClassName={clsx(classes.activeLink)}>
                <span className={classes.title}>
                  <Box className={classes.iconContainer} component="span">
                    <item.Icon />
                  </Box>
                  <span>{item.title}</span>
                  {item.hasChipContent && chipContents && (
                    <ReturnByDateChip returnByDate={chipContents} />
                  )}
                </span>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </Card>
  );
};

export default withStyles(styles)(DesktopMenu);
