import React from 'react';
import {Grid, Stack} from '@mui/material';
import {ReadingLevelEmpty} from '../ReadingLevel/ReadingLevel.empty';
import {ReadingRecordEmpty} from '../ReadingRecord/ReadingRecord.empty';
import {ReadingStatsEmpty} from '../ReadingStats/ReadingStats.empty';
import {TrickyWordsEmpty} from '../TrickyWords/TrickyWords.empty';
import {LessonEmpty} from '../Lesson/Lesson.empty';
import {ReadingRateEmpty} from '../ReadingRate/ReadingRate.empty';

type Props = {
  profileName: string;
  showReadingRate?: boolean;
};

export const DesktopEmptyState: React.FC<Props> = ({
  profileName,
  showReadingRate,
}) => (
  <Grid container spacing={2}>
    <Grid item md={6}>
      <Stack
        sx={{
          alignItems: 'center',
          gap: '16px',
        }}>
        <ReadingLevelEmpty profileName={profileName} />
        <ReadingStatsEmpty />
        <ReadingRecordEmpty />
      </Stack>
    </Grid>

    <Grid item md={6}>
      <Stack
        sx={{
          alignItems: 'center',
          gap: '16px',
        }}>
        {showReadingRate && <ReadingRateEmpty profileName={profileName} />}
        <TrickyWordsEmpty profileName={profileName} />
        <LessonEmpty profileName={profileName} />
      </Stack>
    </Grid>
  </Grid>
);
