import React from 'react';
import {Box, Typography} from '@mui/material';
import Logo from 'customerPortal/components/Banner/Logo/logo';
import {blue500} from 'shared/styles/palette';

export const PdfHeader = ({profileName}: {profileName: string}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        mb: 2,
      }}>
      <Logo nonResponsive={true} />
      <Typography
        sx={{
          fontSize: '24px',
          fontWeight: 800,
          color: blue500,
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          textAlign: 'center',
        }}>
        {profileName}'s Progress Report
      </Typography>
    </Box>
  );
};
