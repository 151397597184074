import {useState} from 'react';
import AnalyticsService from '../../../analytics/Analytics.service';
import {BillingSource} from '../../../shared/types/customer';
import {isTrialing} from '../../../shared/utils/subscriptionStatus';
import {CancellationReasonsType, other} from './constants';
import {CancellationInput} from './types';
import {
  EXTEND_TRIAL_MUTATION,
  OFFER_DISCOUNT_MUTATION,
} from './CancellationForm.query';
import {useMutation} from '@apollo/client';

export function cancelSubscriptionWithMutation(
  cancelSubscription: (options: any) => Promise<any>,
) {
  return async (input: CancellationInput) => {
    try {
      const {data} = await cancelSubscription({
        variables: {
          input,
        },
      });
      return data;
    } catch (error) {
      console.log('cancel subscription:', error);
      return;
    }
  };
}

export function downgradeSubscriptionWithMutation(
  downgradeSubscription: (options: any) => Promise<any>,
) {
  return async () => {
    try {
      const {data} = await downgradeSubscription({
        variables: {},
      });
      return data;
    } catch (error) {
      console.log('downgrade subscription to monthly:', error);
      return;
    }
  };
}

export function isRevenueCat(billingSource: string) {
  return billingSource === BillingSource.ReveneuCat;
}

export function isStripe(billingSource: string) {
  return billingSource === BillingSource.Stripe;
}

export function parseCancellationInput(
  choices: CancellationReasonsType,
  input: CancellationInput,
) {
  const {cancelCategory, cancelDetail, feedback} = input;
  const category = choices[cancelCategory]?.label || other;
  const detail =
    choices[cancelCategory]?.subchoices[cancelDetail]?.label || other;

  return {
    cancelCategory: category,
    cancelDetail: detail,
    feedback,
  };
}

export function trackCancellation(
  cancellationReasonChoices: CancellationReasonsType,
  cancellationDetails: CancellationInput,
  billingSource: string,
  subscriptionStatus: string,
): void {
  const isOnTrial = isTrialing(subscriptionStatus);

  const input = parseCancellationInput(
    cancellationReasonChoices,
    cancellationDetails,
  );

  const eventInput = {
    isOnTrial,
    cancelCategory: input.cancelCategory,
    cancelDetail: input.cancelDetail,
    feedback: input.feedback,
    subscriptionSource: billingSource,
  };

  AnalyticsService.trackCancellationReasonSubmitted(eventInput);
}

export function useExtendTrial(
  setHideBackButton: (value: boolean) => void,
  setShowConfirmation: (value: boolean) => void,
  setSnackbarOpen: (value: boolean) => void,
) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [extendTrial, {loading}] = useMutation(EXTEND_TRIAL_MUTATION, {
    onCompleted: response => {
      if (response?.extendTrialPeriod?.success) {
        setHideBackButton(true);
        setShowConfirmation(true);
      }
    },
    onError: error => {
      setErrorMessage(error.message);
      setSnackbarOpen(true);
    },
  });

  return {extendTrial, loading, errorMessage};
}

export function useOfferDiscount(
  setHideBackButton: (value: boolean) => void,
  setShowConfirmation: (value: boolean) => void,
  setSnackbarOpen: (value: boolean) => void,
) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [offerDiscount, {loading}] = useMutation(OFFER_DISCOUNT_MUTATION, {
    onCompleted: data => {
      if (data?.offerDiscount?.success) {
        setHideBackButton(true);
        setShowConfirmation(true);
      }
    },
    onError: error => {
      setErrorMessage(error.message);
      setSnackbarOpen(true);
    },
  });

  return {offerDiscount, loading, errorMessage};
}
