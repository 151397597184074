import React from 'react';
import {Typography} from '@mui/material';
import {gray700} from 'shared/styles/palette';
import {Dialog} from './Dialog';
import {Button} from '../Button';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const FeaturePreviewDialog: React.FC<Props> = ({open, onClose}) => {
  return (
    <Dialog open={open} onClose={onClose} title="Sharing is Coming Soon!">
      <Typography color={gray700} mb={3} textAlign="center">
        In the meantime you can print to PDF to share your child’s Progress
        Report.
      </Typography>

      <Button label="Ok" onClick={onClose} />
    </Dialog>
  );
};
