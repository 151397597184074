import React, {useState} from 'react';
import {Marker} from './Marker';
import {BoxWhisker} from './BoxWhisker';
import {BoxWhiskerContainer} from './BoxWhisker.container';
import {ReactComponent as WhiskerIndex} from 'shared/assets/whiskerIndex.svg';

type Props = {
  wpm: number;
  minWpm: number;
  maxWpm: number;
  lowerQuartileWpm: number;
  upperQuartileWpm: number;
  defaultLowWpm: number;
  defaultHighWpm: number;
};

export const BoxWhiskerPlot: React.FC<Props> = ({
  wpm,
  minWpm,
  maxWpm,
  lowerQuartileWpm,
  upperQuartileWpm,
  defaultLowWpm,
  defaultHighWpm,
}) => {
  const [whiskerWidth, setWhiskerWidth] = useState(0);

  const boxWidth = 154.587;
  const whiskerUsableWidth = Math.max(whiskerWidth - boxWidth, 0);
  const paddedWhiskerWidth = Math.max(whiskerUsableWidth - 20, 0);

  const normalizeOutlierWpm = () => {
    if (wpm < minWpm) return defaultLowWpm;
    if (wpm > maxWpm) return defaultHighWpm;
    return wpm;
  };

  const getMarkerPosition = (wpm: number) => {
    if (wpm >= lowerQuartileWpm && wpm <= upperQuartileWpm) {
      const offsetWithinBox =
        ((wpm - lowerQuartileWpm) / (upperQuartileWpm - lowerQuartileWpm)) *
        boxWidth;
      return `calc(50% - ${boxWidth / 2}px + ${offsetWithinBox}px)`;
    }

    if (wpm < lowerQuartileWpm) {
      const offsetLeftWhisker =
        ((lowerQuartileWpm - wpm) / (lowerQuartileWpm - minWpm)) *
        (paddedWhiskerWidth / 2);
      return `calc(50% - ${boxWidth / 2}px - ${offsetLeftWhisker}px)`;
    }

    const offsetRightWhisker =
      ((wpm - upperQuartileWpm) / (maxWpm - upperQuartileWpm)) *
      (paddedWhiskerWidth / 2);
    return `calc(50% + ${boxWidth / 2}px + ${offsetRightWhisker}px)`;
  };

  const normalizedWpm = normalizeOutlierWpm();

  return (
    <BoxWhiskerContainer>
      <BoxWhisker boxWidth={boxWidth} setWhiskerWidth={setWhiskerWidth} />
      <Marker
        position={getMarkerPosition(normalizedWpm)}
        value={normalizedWpm}
        marker={<WhiskerIndex />}
      />
    </BoxWhiskerContainer>
  );
};
