import {Box, Grid, Typography} from '@mui/material';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import Section from 'customerPortal/components/Section';
import useMobileLayout from 'customerPortal/utils/useMobileLayout';
import React from 'react';
import {ReactComponent as TryOneMonthSVG} from 'shared/assets/tryOneMonth.svg';
import Button, {ButtonType} from 'shared/components/common/Button';
import styles from './CancellationOfferMonthly.styles';

interface Props extends WithStyles<typeof styles> {
  onConfirm: () => Promise<void>;
  onBack?: () => void;
}

const CancellationOfferMonthly = ({
  classes,
  onConfirm,
  onBack = () => {},
}: Props) => {
  const isMobile = useMobileLayout();

  const gridSize = isMobile ? 12 : 6;

  return (
    <>
      <Grid container className={classes.content}>
        <Box className={classes.topContainer}>
          {isMobile && <Section title="Cancellation" />}

          <Box>
            <TryOneMonthSVG className={classes.icon} />
          </Box>

          <Typography variant={'h6'} align={'center'}>
            Try a month for $14.99 instead?
          </Typography>

          <Typography
            variant={'body1'}
            align={'center'}
            className={classes.body}>
            Just one month can make a difference in your child's reading
            progress. Switch to a monthly subscription for $14.99 and cancel
            anytime.
          </Typography>
        </Box>
        <Box>
          <Grid container>
            <Grid container spacing={1}>
              <Grid item xs={gridSize}>
                <Button
                  className={clsx(classes.button, classes.backButton)}
                  variant={ButtonType.Secondary}
                  onClick={onBack}>
                  No I am not interested
                </Button>
              </Grid>
              <Grid item xs={gridSize}>
                <Button className={clsx(classes.button)} onClick={onConfirm}>
                  Try Monthly Plan
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default withStyles(styles)(CancellationOfferMonthly);
