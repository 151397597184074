import {Box, Typography} from '@mui/material';
import clsx from 'clsx';
import {subscriptionIcon} from 'customerPortal/utils/bundleIcons';
import React, {FC} from 'react';
import {subscriptionInfoStyles} from './AccountSubscription.styles';
import {QueryResult} from './AccountSubscription.types';
import {toTitleCase} from './AcountSubscription.service';

type Props = QueryResult & {
  isInactive?: boolean;
  productType: string | undefined;
  toOfferMonthlyDowngrade: boolean;
};

export const SubscriptionInfo: FC<Props> = ({
  title,
  description,
  name,
  isInactive,
  productType,
  toOfferMonthlyDowngrade,
}: Props) => {
  const classes = subscriptionInfoStyles();
  const formatedTitle = toOfferMonthlyDowngrade
    ? 'Ello Monthly Subscription'
    : toTitleCase(title);
  return (
    <Box>
      <Box className={classes.titleContainer}>
        <img
          src={subscriptionIcon(productType, name)}
          alt=""
          className={clsx(classes.icon, isInactive && classes.inactiveIcon)}
        />
        <Typography component="h1" variant="h5" className={classes.name}>
          {formatedTitle}
        </Typography>
      </Box>
      <span className={classes.desc}>
        <Typography component="p" variant="h6" className={classes.planSubtitle}>
          {description}
        </Typography>
      </span>
    </Box>
  );
};
