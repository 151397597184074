import React from 'react';
import {ProgressCard} from '../components/ProgressCard/ProgressCard';
import {ReactComponent as ReadingRateIcon} from 'shared/assets/icons/ReadingRateIcon.svg';
import {QueryResult, ReadingRateData} from '../types';
import {ReadingRateContent} from './ReadingRate.content';

type Props = {
  profileName: string;
  profileId: string;
  readingRate: QueryResult<ReadingRateData>;
};

export const ReadingRate: React.FC<Props> = ({
  profileName,
  profileId,
  readingRate,
}) => {
  return (
    <ProgressCard
      icon={<ReadingRateIcon />}
      title={`${profileName}'s Reading Rate`}>
      <ReadingRateContent
        profileName={profileName}
        profileId={profileId}
        readingRate={readingRate}
      />
    </ProgressCard>
  );
};
