import React from 'react';
import {Box, List, ListItemButton, Typography} from '@mui/material';
import {Dialog} from './Dialog';
import {gray, turquoise900} from 'shared/styles/palette';

const shareOptions = [
  {label: 'Teacher', emoji: '🍎'},
  {label: 'Friend or Family', emoji: '🧸'},
  {label: 'Myself', emoji: '👋 '},
  {label: 'Oops, not sharing yet'},
];

type Props = {
  open: boolean;
  onClose: () => void;
  onPreviewOpen: () => void;
};

export const ShareOptionsDialog: React.FC<Props> = ({
  open,
  onClose,
  onPreviewOpen,
}) => {
  const handleOptionClick = (label: string) => {
    window.analytics.track('ParentDashboard  ShareOptionSelected', {
      option: label,
    });
    onClose();
    onPreviewOpen();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Who do you want to share this Progress Report with?">
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '12px',
        }}>
        {shareOptions.map((option, index) => (
          <ListItemButton
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '12px',
              width: '100%',
              height: '56px',
              borderRadius: '8px',
              padding: '16px 0px 14px 0px',
              border: `1px solid ${turquoise900}`,
              background: gray,
            }}
            key={index}
            onClick={() => handleOptionClick(option.label)}>
            <Box
              component="span"
              sx={{
                fontSize: '22px',
              }}>
              {option?.emoji}
            </Box>
            <Typography sx={{fontWeight: '700'}}>{option.label}</Typography>
          </ListItemButton>
        ))}
      </List>
    </Dialog>
  );
};
