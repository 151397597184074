import {Box} from '@mui/material';
import React from 'react';
import {ReadingLevelEmpty} from '../ReadingLevel/ReadingLevel.empty';
import {ReadingStatsEmpty} from '../ReadingStats/ReadingStats.empty';
import {ReadingRecordEmpty} from '../ReadingRecord/ReadingRecord.empty';
import {TrickyWordsEmpty} from '../TrickyWords/TrickyWords.empty';
import {LessonEmpty} from '../Lesson/Lesson.empty';
import {ReadingRateEmpty} from '../ReadingRate/ReadingRate.empty';

type Props = {
  profileName: string;
  showReadingRate?: boolean;
};

export const MobileEmptyState: React.FC<Props> = ({
  profileName,
  showReadingRate,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '24px',
        width: '100%',
      }}>
      <ReadingLevelEmpty profileName={profileName} />
      {showReadingRate && <ReadingRateEmpty profileName={profileName} />}
      <ReadingStatsEmpty />
      <ReadingRecordEmpty />
      <TrickyWordsEmpty profileName={profileName} />
      <LessonEmpty profileName={profileName} />
    </Box>
  );
};
