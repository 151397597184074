import React from 'react';
import {useHistory} from 'react-router';
import {Box, IconButton, Typography} from '@mui/material';
import {blue500, gray900} from 'shared/styles/palette';
import {getProfileCharacter} from '../ProgressReportScreen.service';
import {ProfileCharacter} from '../types';
import {Profile} from 'shared/types/profile';
import useMobileLayout from 'customerPortal/utils/useMobileLayout';
import {ReactComponent as LeftChevron} from 'shared/assets/icons/left-chevron.svg';

type Props = {
  selectedProfile: Profile | undefined;
};

export const ReadingRecordHeader: React.FC<Props> = ({selectedProfile}) => {
  const history = useHistory();
  const isMobile = useMobileLayout();
  const ProfileCharacterImage = getProfileCharacter(
    selectedProfile?.profileCharacter as ProfileCharacter,
  );

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '16px',
        marginBottom: '16px',
        alignItems: 'center',
      }}>
      {!isMobile && (
        <IconButton onClick={() => history.goBack()}>
          <LeftChevron />
        </IconButton>
      )}
      <Box sx={{display: 'flex', gap: '16px'}}>
        <ProfileCharacterImage />
        <Box>
          <Typography
            sx={{
              fontSize: '22px',
              fontWeight: '700',
              textTransform: 'capitalize',
              lineHeight: '1.4',
              letterSpacing: '-0.176px',
              color: blue500,
            }}>
            {selectedProfile
              ? `${selectedProfile.name}'s Reading Record`
              : 'Reading Record'}
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              lineHeight: '1.6',
              letterSpacing: '-0.048px',
              color: gray900,
            }}
            variant="body2">
            Past 7 days
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
