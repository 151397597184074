import React, {useState} from 'react';
import {useParams} from 'react-router-dom';

import Section from '../../../../components/Section';
import EditProfilePicture from './EditProfileScreen.picture';
import EditProfileName from './EditProfileScreen.name';
import EditProfileReadingLevel from './EditProfileScreen.reading-level';
import Box from '@mui/material/Box';
import {useProfiles} from './EditProfileScreen.service';
import RemoveProfileButton from './EditProfileScreen.remove-profile.button';
import featureFlags, {
  FEATURE_IDS,
} from '../../../../../shared/utils/featureFlags';
import RemoveProfileModal from './EditProfileScreen.remove-profile.modal';
import Header from '../Components/Header';

const EditProfile = () => {
  const {profileId} = useParams<{profileId?: string}>();

  const {loading, data, error, refetch} = useProfiles();

  const selectedProfile = data?.profiles?.find(({id}) => id === profileId);

  const profileName = selectedProfile?.name;
  const profileCharacter = selectedProfile?.profileCharacter;
  const readingLevel = selectedProfile?.readingLevel;
  const gradeAndMonth = selectedProfile?.gradeAndMonth;
  const requestedLevelAdjustmentAt =
    selectedProfile?.requestedLevelAdjustmentAt;

  const customerHasMoreThanOneProfile =
    data?.profiles?.length && data?.profiles?.length > 1;
  const showRemoveProfileButton =
    featureFlags.isOn(FEATURE_IDS.REMOVE_PROFILE) &&
    customerHasMoreThanOneProfile;

  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => {
    setOpenModal(false);
  };

  const openRemoveProfileModal = () => {
    setOpenModal(true);
  };
  return (
    <Section
      title={<Header oldPath="My Children" currentPath={profileName} />}
      loading={loading}
      error={error ? 'Error loading profile' : ''}>
      <Box
        sx={{
          maxWidth: '600px',
        }}>
        <EditProfilePicture
          id={profileId}
          profileCharacter={profileCharacter}
          refetchProfile={refetch}
        />

        <EditProfileName
          name={profileName}
          id={profileId}
          refetchProfile={refetch}
        />

        <EditProfileReadingLevel
          readingLevel={gradeAndMonth || readingLevel}
          profileId={profileId || ''}
          requestedLevelAdjustmentAt={requestedLevelAdjustmentAt}
        />

        {showRemoveProfileButton && (
          <RemoveProfileButton
            profileId={profileId}
            openModal={openRemoveProfileModal}
          />
        )}
      </Box>

      <RemoveProfileModal open={openModal} onCloseModal={closeModal} />
    </Section>
  );
};

export default EditProfile;
