import React from 'react';
import {Grid, Stack} from '@mui/material';
import {Lesson} from '../Lesson/Lesson';
import {ReadingLevel} from '../ReadingLevel/ReadingLevel';
import {ReadingRecord} from '../ReadingRecord/ReadingRecord';
import {ReadingStats} from '../ReadingStats/ReadingStats';
import {TrickyWords} from '../TrickyWords/TrickyWords';
import {
  CurriculumProgress,
  LifeTimeAchievementsReport,
  QueryResult,
  ReadingRateData,
  ReadingRecordsList,
  WeeklyAchievement,
} from '../types';
import {ReadingRate} from '../ReadingRate/ReadingRate';
import {OverallStats} from '../OverallStats/OverallStats';

type Props = {
  profileName: string;
  profileId: string;
  weeklyAchievements: QueryResult<WeeklyAchievement>;
  readingRecords: QueryResult<ReadingRecordsList[]>;
  curriculumProgress: QueryResult<CurriculumProgress>;
  readingRate: QueryResult<ReadingRateData>;
  lifeTimeAchievements: QueryResult<LifeTimeAchievementsReport>;
  showReadingRate?: boolean;
};

export const DesktopDashboard: React.FC<Props> = ({
  profileName,
  profileId,
  curriculumProgress,
  readingRecords,
  weeklyAchievements,
  readingRate,
  showReadingRate,
  lifeTimeAchievements,
}) => (
  <>
    <OverallStats lifeTimeAchievements={lifeTimeAchievements} />
    <Grid container spacing={3} mb="16px">
      <Grid item md={6}>
        <Stack
          sx={{
            alignItems: 'center',
            gap: '16px',
          }}>
          <ReadingLevel
            profileName={profileName}
            profileId={profileId}
            curriculumProgress={curriculumProgress}
          />
          <ReadingStats
            profileName={profileName}
            weeklyAchievements={weeklyAchievements}
          />
          <ReadingRecord
            profileName={profileName}
            profileId={profileId}
            readingRecords={readingRecords}
          />
        </Stack>
      </Grid>

      <Grid item md={6}>
        <Stack
          sx={{
            alignItems: 'center',
            gap: '16px',
          }}>
          {showReadingRate && (
            <ReadingRate
              profileName={profileName}
              profileId={profileId}
              readingRate={readingRate}
            />
          )}
          <TrickyWords
            profileName={profileName}
            weeklyAchievements={weeklyAchievements}
          />
          <Lesson
            profileName={profileName}
            curriculumProgress={curriculumProgress}
          />
        </Stack>
      </Grid>
    </Grid>
  </>
);
