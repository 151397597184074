import React from 'react';
import {Link as MuiLink} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';
import {turquoise900} from 'shared/styles/palette';

type Props = {
  to: string;
  text: string;
  onClick?: () => void;
};

export const Link: React.FC<Props> = ({to, text, onClick}) => {
  return (
    <MuiLink
      component={RouterLink}
      to={to}
      sx={{color: turquoise900, fontWeight: 700, textDecoration: 'none'}}
      onClick={onClick}>
      {text}
    </MuiLink>
  );
};
