import React from 'react';
import {Chip, Typography, Box} from '@mui/material';
import clsx from 'clsx';
import {subscriptionStatusText} from './AcountSubscription.service';
import {isActiveSubscription} from '../../../../shared/utils/subscriptionStatus';
import {accountSubscriptionStyles} from './AccountSubscription.styles';
import useMobileLayout from '../../../utils/useMobileLayout';
interface props {
  subscriptionStatus?: string;
  subscriptionPeriodEnd?: number;
}

const SectionHeader = ({subscriptionStatus, subscriptionPeriodEnd}: props) => {
  const classes = accountSubscriptionStyles();
  const isMobile = useMobileLayout();
  const hasSubscriptionDetails = subscriptionStatus || subscriptionPeriodEnd;

  const subscriptionStatusInfo = subscriptionStatusText(
    subscriptionStatus,
    subscriptionPeriodEnd,
  );

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: isMobile ? 'space-between' : 'flex-start',
        flex: 1,
      }}>
      <Typography component="h1" variant="h5">
        Subscription
      </Typography>

      {hasSubscriptionDetails && (
        <Chip
          label={subscriptionStatusInfo}
          variant="outlined"
          className={clsx(
            classes.subscriptionChip,
            isActiveSubscription(subscriptionStatus)
              ? classes.activeSubscriptionChip
              : classes.inactiveSubscriptionChip,
          )}
        />
      )}
    </Box>
  );
};

export default SectionHeader;
