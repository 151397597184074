import {useQuery} from '@apollo/client';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {QUERY_ACCOUNT_DETAILS} from '../customerPortal/screens/AccountSettingsScreen/AccountDetailsScreen/AccountDetails.query';

export function usePageViews() {
  const location = useLocation();

  useEffect(() => {
    window.analytics.page(location.pathname);
  }, [location]);
}

export function useCustomer(skip?: boolean): {customer: {tags: string[]}} {
  const {data} = useQuery(QUERY_ACCOUNT_DETAILS, {skip});
  const customer = data?.customer as {tags: string[]};
  return {customer};
}
