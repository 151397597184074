import {useQuery} from '@apollo/client';
import html2canvas from 'html2canvas';
import {jsPDF} from 'jspdf';
import {
  curriculumProgressReportQuery,
  lifeTimeAchievementsReportQuery,
  profileQuestsByIDQuery,
  readingRateQuery,
  readingRecordQuery,
  weeklyAchievementsReportQuery,
} from './ProgressReportScreen.query';
import {
  CurriculumProgress,
  LifeTimeAchievementsReport,
  ProfileCharacter,
  QueryResult,
  Quest,
  ReadingRateData,
  ReadingRecordsList,
  WeeklyAchievement,
} from './types';
import {ReactComponent as Bull} from 'shared/assets/profileCharacters/bullCircular.svg';
import {ReactComponent as Cat} from 'shared/assets/profileCharacters/catCircular.svg';
import {ReactComponent as Robot} from 'shared/assets/profileCharacters/robotCircular.svg';
import {ReactComponent as DinoBird} from 'shared/assets/profileCharacters/dinoBirdCircular.svg';
import {ReactComponent as Bird} from 'shared/assets/profileCharacters/birdCircular.svg';
import {ReactComponent as Unicorn} from 'shared/assets/profileCharacters/unicornCircular.svg';

export function useLifeTimeAchievementsReport(
  profileId: string,
): QueryResult<LifeTimeAchievementsReport> {
  const {data, error, loading} = useQuery(lifeTimeAchievementsReportQuery, {
    variables: {profileId},
    skip: !profileId,
  });

  const achievementsReport = data?.lifeTimeAchievementsReport ?? null;

  return {data: achievementsReport, error, loading};
}

export function useWeeklyAchievementsReport(
  profileId: string,
  hasQuests: boolean,
): QueryResult<WeeklyAchievement> {
  const {data, error, loading} = useQuery(weeklyAchievementsReportQuery, {
    variables: {profileId},
    skip: !hasQuests,
  });

  const weeklyAchievements = data?.weeklyAchievementsReport ?? null;

  return {data: weeklyAchievements, error, loading};
}

export function useReadingRecord(
  profileId: string,
  hasQuests: boolean,
): QueryResult<ReadingRecordsList[]> {
  const {data, error, loading} = useQuery(readingRecordQuery, {
    variables: {profileId},
    skip: !profileId && !hasQuests,
  });

  const readingRecords = data?.readingRecord ?? null;

  return {data: readingRecords, error, loading};
}

export function useCurriculumProgressReport(
  profileId: string,
  hasQuests: boolean,
): QueryResult<CurriculumProgress> {
  const {data, error, loading} = useQuery(curriculumProgressReportQuery, {
    variables: {profileId},
    skip: !hasQuests,
  });

  return {
    data: data?.curriculumProgressReport ?? null,
    error,
    loading,
  };
}

export function useProfileQuestsByID(
  profileId: string,
  filter?: {status?: string},
): QueryResult<Quest[]> {
  const {data, error, loading} = useQuery(profileQuestsByIDQuery, {
    variables: {profileId, filter},
    skip: !profileId,
  });

  return {
    data: data?.profileQuestsByID.quests ?? null,
    error,
    loading,
  };
}

export function useReadingRate(
  profileId: string,
  readingLevel: string,
): QueryResult<ReadingRateData> {
  const {data, error, loading} = useQuery<{readingRate: ReadingRateData}>(
    readingRateQuery,
    {
      variables: {profileId},
      skip: !profileId || isEarlyReadingLevel(readingLevel),
    },
  );

  return {
    data: data?.readingRate ?? null,
    error,
    loading,
  };
}

const profileCharacterMapping: Record<ProfileCharacter, React.FC> = {
  [ProfileCharacter.Bull]: Bull,
  [ProfileCharacter.Cat]: Cat,
  [ProfileCharacter.Robot]: Robot,
  [ProfileCharacter.DinoBird]: DinoBird,
  [ProfileCharacter.Bird]: Bird,
  [ProfileCharacter.Unicorn]: Unicorn,
};

export const getProfileCharacter = (profileCharacter?: ProfileCharacter) => {
  const Character =
    profileCharacterMapping[profileCharacter ?? ProfileCharacter.Bull];

  return Character;
};

export const getReadingStatsCopy = (
  profileName: string,
  gradeRole: string,
  booksRead: number,
) => {
  if (booksRead < 5) {
    return `Let’s help ${profileName} catch up to the pace of most ${gradeRole}s. Encourage them to complete at least one quest each week to stay on track. To accelerate their learning, aim for two or more quests per week!`;
  }

  if (booksRead < 10) {
    return `Great job, ${profileName}! They’re keeping pace with most ${gradeRole}s. To continue progressing steadily, encourage them to complete at least one quest per week. For faster growth, aim for two or more quests per week!`;
  }

  return `Wow, ${profileName} is on fire! They’re learning faster than most ${gradeRole}s. Encourage them to keep up the fantastic work by completing two or more quests per week to maintain this amazing momentum!`;
};

export function interpolateLessonDescription(
  profileName: string,
  text?: string,
) {
  if (!text) {
    return '';
  }

  return text.replace(/%CHILD_NAME%/g, profileName);
}

export function formatNumber(number: number): string {
  if (number < 1000) return number.toString();
  if (number < 10000) return number.toLocaleString();
  return `${Math.floor(number / 1000)}K`;
}

function isTouchDevice(): boolean {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}

function isIphone(userAgent: string): boolean {
  return /iphone/.test(userAgent);
}

function isIpad(userAgent: string): boolean {
  return (
    /ipad/.test(userAgent) || (/macintosh/.test(userAgent) && isTouchDevice())
  );
}

export function isDesktop(userAgent: string): boolean {
  return (
    /windows|macintosh|linux/.test(userAgent) &&
    !/mobile/.test(userAgent) &&
    !isIpad(userAgent)
  );
}

export const deviceType = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (isIphone(userAgent)) {
    return 'iPhone';
  }

  if (isIpad(userAgent)) {
    return 'iPad';
  }

  if (isDesktop(userAgent)) {
    return 'Desktop';
  }
  return 'Other';
};

export function isEarlyReadingLevel(readingLevel: string): boolean {
  const formattedLevel = readingLevel.trim().toLowerCase();
  return formattedLevel.startsWith('a') || formattedLevel.startsWith('b');
}

export const normalizeOutlierWpm = (data: ReadingRateData): number => {
  const {wpm, minWpm = 0, maxWpm = 0, defaultLowWpm, defaultHighWpm} = data;

  if (wpm < minWpm) return defaultLowWpm ?? 0;
  if (wpm > maxWpm) return defaultHighWpm ?? 0;
  return wpm;
};

export async function generateReportPdf(
  componentRef: React.RefObject<HTMLDivElement>,
): Promise<jsPDF | undefined> {
  if (!componentRef.current) {
    return;
  }

  const canvas = await html2canvas(componentRef.current, {
    scale: 2,
    useCORS: true,
  });

  const imgData = canvas.toDataURL('image/png');

  const pdf = new jsPDF('portrait', 'mm');
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();

  const imgRatio = canvas.width / canvas.height;
  let imgWidth = pdfWidth - 10;
  let imgHeight = imgWidth / imgRatio;

  const maxImgHeight = pdfHeight - 10;
  if (imgHeight > maxImgHeight) {
    imgHeight = maxImgHeight;
    imgWidth = imgHeight * imgRatio;
  }

  const imageX = (pdfWidth - imgWidth) / 2;
  const imageY = 10;
  pdf.addImage(imgData, 'PNG', imageX, imageY, imgWidth, imgHeight);

  return pdf;
}

export async function shareReport(pdf: jsPDF, fileName: string) {
  const pdfBlob = pdf.output('blob');
  const file = new File([pdfBlob], fileName, {
    type: 'application/pdf',
  });

  if (navigator.canShare && navigator.canShare({files: [file]})) {
    try {
      await navigator.share({
        files: [file],
      });
    } catch (error) {
      console.error('Error sharing file:', error);
      return;
    }
  } else {
    console.log('Error sharing file');
    return;
  }
}

const convertBlobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = () => reject(reader.error);
    reader.readAsDataURL(blob);
  });
};

export const fetchBase64Images = async (
  urls: (string | undefined)[],
): Promise<string[]> => {
  return Promise.all(
    urls.map(async url => {
      if (!url) return '';

      try {
        const res = await fetch(url, {
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            Pragma: 'no-cache',
            Expires: '0',
          },
        });
        const blob = await res.blob();
        return await convertBlobToBase64(blob);
      } catch (error) {
        console.error('Failed to fetch image:', error);
        return '';
      }
    }),
  );
};

export function pluralize(
  count: number,
  singular: string,
  plural: string,
): string {
  return `${count} ${count === 1 ? singular : plural}`;
}
