import {FunctionComponent, SVGProps} from 'react';
import {ReactComponent as IconAccount} from 'shared/assets/icons/iconAccount.svg';
import {ReactComponent as IconProfiles} from 'shared/assets/icons/iconProfiles.svg';
import {ReactComponent as IconSubscription} from 'shared/assets/icons/iconSubscription.svg';
import {ReactComponent as ShareIconBW} from 'shared/assets/icons/shareIconBW.svg';
import {ReactComponent as IconProgress} from 'shared/assets/icons/iconProgress.svg';
import {ReactComponent as IconCoCreate} from 'shared/assets/icons/iconCoCreate.svg';
import {PRIVATE_ROUTES} from 'shared/components/Route/routes';
import featureFlags, {FEATURE_IDS} from 'shared/utils/featureFlags';
import {
  isDigitalSubscription,
  supportsDigital,
} from '../../shared/utils/productType';
import {Customer, isInAppCustomer} from 'shared/types/customer';
import {isQuestsEnabled} from 'shared/utils/tags';

export interface Navigation {
  to: string;
  title: string;
  subtitle: string;
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  isEnabled: () => boolean;
  hasChipContent?: boolean;
  isHighlighted?: boolean;
}

export function getNavigationItems(
  showReferralLink: boolean,
  customer?: Customer,
) {
  const {
    productType: customerProductType,
    billingSource,
    tags,
  } = customer || {};

  const inAppCustomer = isInAppCustomer(billingSource);

  const showMergedAccountDetails = featureFlags.isOn(
    FEATURE_IDS.ACCOUNT_DETAILS_MERGED,
  );

  const NAVIGATION_ITEMS: Navigation[] = [
    {
      to: PRIVATE_ROUTES.CREATE_STORY.path,
      title: 'Create a story',
      subtitle: 'Make a custom book for your child',
      Icon: IconCoCreate,
      isEnabled: () => featureFlags.isOn(FEATURE_IDS.CREATE_STORY_SCREEN),
      isHighlighted: true,
    },
    {
      to: PRIVATE_ROUTES.PROGRESS_REPORT.path,
      title: 'Progress Report',
      subtitle: 'Track your child’s reading progress',
      Icon: IconProgress,
      isEnabled: () =>
        featureFlags.isOn(FEATURE_IDS.PARENT_DASHBOARD_SCREEN) &&
        supportsDigital(customerProductType) &&
        isQuestsEnabled(tags),
    },
    {
      to: PRIVATE_ROUTES.ACCOUNT_DETAILS.path,
      title: 'Account Details',
      subtitle: 'View email and phone number',
      Icon: IconAccount,
      isEnabled: () => !showMergedAccountDetails,
    },
    {
      to: PRIVATE_ROUTES.ACCOUNT_SUB.path,
      title: 'Subscription',
      subtitle: 'Adjust your subscription and payment method',
      Icon: IconSubscription,
      isEnabled: () => featureFlags.isOn(FEATURE_IDS.CUSTOMER_PORTAL_SUB),
    },
    {
      to: PRIVATE_ROUTES.ACCOUNT_PROFILES.path,
      title: 'Profiles',
      subtitle: 'Edit or add child profiles and levels',
      Icon: IconProfiles,
      isEnabled: () =>
        featureFlags.isOn(FEATURE_IDS.PROFILES_SCREEN) &&
        isDigitalSubscription(customerProductType),
    },
    {
      to: PRIVATE_ROUTES.ACCOUNT_REFERRAL.path,
      title: 'Earn rewards!',
      subtitle: inAppCustomer
        ? 'Get an Ello plushie'
        : 'Get 1 free month of Ello',
      Icon: ShareIconBW,
      isEnabled: () =>
        showReferralLink &&
        featureFlags.isOn(FEATURE_IDS.REFERRAL_REWARDS_SCREEN),
    },
  ];

  return NAVIGATION_ITEMS;
}
