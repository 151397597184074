import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';

import {ReactComponent as Close} from 'shared/assets/icons/CloseIcon.svg';
import {ReactComponent as CheckBoxMarked} from 'shared/assets/icons/checkbox-marked.svg';
import {ReactComponent as CheckBox} from 'shared/assets/icons/checkbox.svg';
import {Button} from '../Button';
import {gray700} from 'shared/styles/palette';
import {CheckListItem} from './ShareDialog.CheckListItem';

type Props = {
  profileName: string;
  open: boolean;
  onClose: () => void;
};

export const ShareDialog: React.FC<Props> = ({profileName, open, onClose}) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '16px',
          padding: '24px',
        },
      }}>
      <IconButton
        size="small"
        disableRipple
        sx={{
          alignSelf: 'flex-start',
          padding: 0,
          mb: '16px',
          '&:hover': {
            background: 'none',
          },
        }}
        onClick={onClose}>
        <Close />
      </IconButton>

      <DialogTitle
        sx={{
          fontSize: '22px',
          fontWeight: '700',
          padding: 0,
          marginBottom: '16px',
          textAlign: 'center',
          lineHeight: '1.4',
          letterSpacing: '-.066px',
        }}>
        Now share {profileName}'s progress!
      </DialogTitle>

      <DialogContent sx={{[theme.breakpoints.down('sm')]: {padding: 0}}}>
        <Typography
          sx={{
            color: gray700,
            fontWeight: 400,
          }}>
          Recognition from parents, teachers, and friends is so motivating for
          children learning to read
        </Typography>

        <CheckListItem
          icon={<CheckBoxMarked width="100%" />}
          label={`Download ${profileName}'s Progress Report`}
        />

        <CheckListItem
          icon={<CheckBox width="100%" />}
          label="Share it with teachers, family, and friends"
        />
      </DialogContent>

      <DialogActions sx={{justifyContent: 'center'}}>
        <Button sx={{width: 'auto'}} onClick={onClose} label="OK" />
      </DialogActions>
    </Dialog>
  );
};
