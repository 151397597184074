import React from 'react';
import {Button} from '@mui/material';
import {SxProps, Theme, useTheme} from '@mui/system';
import {orange500, yellow500} from 'shared/styles/palette';
import {ReactComponent as IconStars} from 'shared/assets/icons/iconStars.svg';

type Props = {
  label: string;
  href: string;
  sx?: SxProps<Theme>;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

export const CTA: React.FC<Props> = ({label, href, sx, onClick}) => {
  const theme = useTheme();

  return (
    <Button
      variant="contained"
      disableElevation
      disableRipple
      href={href}
      onClick={onClick}
      target="_blank"
      rel="noopener noreferrer"
      endIcon={<IconStars />}
      sx={{
        background: `linear-gradient(90deg, ${yellow500} 0%, ${orange500} 100%)`,
        width: '100%',
        height: '56px',
        borderRadius: '50px',
        padding: '16px 32px',
        fontWeight: '700',
        letterSpacing: '-.048px',
        [theme.breakpoints.up('sm')]: {
          width: 'fit-content',
          padding: '16px 80px 14px 80px',
        },
        ...sx,
      }}>
      {label}
    </Button>
  );
};
