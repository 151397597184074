/*
 * Main App Entrypoint: this is the main entrypoint for the public client app,
 * hosted at https://portal.helloello.com, that provides onboarding and login
 * functionality.
 */

import {ApolloProvider} from '@apollo/client';
import {GrowthBookProvider} from '@growthbook/growthbook-react';
import {Container, adaptV4Theme} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import {enUS, esES, zhCN} from '@mui/material/locale';
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from '@mui/material/styles';
import AdminLoginScreen from 'adminPortal/screens/AdminLoginScreen';
import AccountSettings from 'customerPortal/screens/AccountSettingsScreen/AccountSettingsScreen';
import AddChildScreen from 'customerPortal/screens/AddChildScreen/AddChildScreen';
import {ParentLoginScreen} from 'customerPortal/screens/CustomerLoginScreen';
import ExternalReaderScreen from 'customerPortal/screens/LibraryScreen/LibraryReaderScreen';
import ExternalListScreen from 'customerPortal/screens/LibraryScreen/LibraryScreen';
import RemoveChildScreen from 'customerPortal/screens/RemoveChildScreen/RemoveChildScreen';
import ResetPasswordScreen from 'customerPortal/screens/ResetPasswordScreen';
import React, {useEffect, useState} from 'react';
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import {PageRoute, PrivateRoute} from 'shared/components/Route';
import {PRIVATE_ROUTES, PUBLIC_ROUTES} from 'shared/components/Route/routes';
import Error, {ErrorMessage} from 'shared/components/common/Error';
import {materialTheme} from 'shared/styles/theme';
import getClient from 'shared/utils/apolloClient';
import featureFlags, {
  FEATURES_ENDPOINT,
  FEATURE_IDS,
} from 'shared/utils/featureFlags';
import {
  Locales,
  LocalizationContext,
  identifyLanguage,
} from 'shared/utils/localization';
import {FirebaseAuthProvider} from 'shared/utils/providers/authProvider';
import {QueryParamProvider, StringParam, useQueryParam} from 'use-query-params';
import AnalyticsProvider from './analytics/AnalyticsProvider';
import AddProfileScreen from './customerPortal/screens/AccountSettingsScreen/ProfilesScreen/AddProfile/AddProfileScreen';
import {LoginStep} from './customerPortal/screens/CustomerLoginScreen/constants';
import CategoriesScreen from './customerPortal/screens/LibraryScreen/CategoriesScreen';
import CategoryScreen from './customerPortal/screens/LibraryScreen/CategoryScreen';
import ExternalFinishBookScreen from './customerPortal/screens/LibraryScreen/LibraryFinishBookScreen';
import ExternalStartBookScreen from './customerPortal/screens/LibraryScreen/LibraryStartBookScreen';
import ReadingListScreen from './customerPortal/screens/LibraryScreen/ReadingListScreen';
import {PasswordResetStep} from './customerPortal/screens/ResetPasswordScreen/ResetPasswordScreen.service';

const App = () => {
  const [language] = useQueryParam('lg', StringParam);

  // We use state since the query params and history are controlled downstream
  // by OnboardingPanel and LanguagePicker
  const [locale, setLocale] = useState(identifyLanguage(language));
  const [client, setClient] = useState(undefined);

  useEffect(() => {
    getClient().then(client => {
      setClient(client);
    });

    // Load feature flags
    fetch(FEATURES_ENDPOINT)
      .then(featureFlagsResult => featureFlagsResult.json())
      .then(json => featureFlags.setFeatures(json.features))
      .catch(error => console.error('Failed to load feature flags', error));
  }, []);

  const theme = createTheme(
    adaptV4Theme(
      materialTheme,
      {[Locales.enUS]: enUS, [Locales.zhCN]: zhCN, [Locales.esES]: esES}[
        locale
      ],
    ),
  );

  if (!client) {
    return (
      <CircularProgress
        style={{
          marginTop: '40px',
          marginBottom: '40px',
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'block',
        }}
      />
    );
  }

  return (
    <GrowthBookProvider growthbook={featureFlags}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <FirebaseAuthProvider>
            <LocalizationContext.Provider value={{locale, setLocale}}>
              <CssBaseline />
              <ApolloProvider client={client}>
                <Container maxWidth="xl" style={{padding: '0 24px'}}>
                  <Router>
                    <AnalyticsProvider>
                      <QueryParamProvider ReactRouterRoute={Route}>
                        <Switch>
                          <Route path={PUBLIC_ROUTES.HEALTH}>
                            <h3>OK</h3>
                          </Route>
                          <Route
                            path={PUBLIC_ROUTES.REQUEST_PASSWORD_RESET}
                            component={ResetPasswordScreen}
                          />
                          <Route
                            path={PUBLIC_ROUTES.RESET_PASSWORD}
                            render={props => (
                              <ResetPasswordScreen
                                {...props}
                                initialResetStep={
                                  PasswordResetStep.ResetPassword
                                }
                              />
                            )}
                          />
                          <Route
                            path={PUBLIC_ROUTES.WELCOME}
                            render={props => (
                              <ResetPasswordScreen
                                {...props}
                                customTitle={'👋 Welcome to Ello!'}
                                customMessage={
                                  'Enter your email to create your password!'
                                }
                              />
                            )}
                          />
                          <Route
                            path={PUBLIC_ROUTES.MANAGE}
                            component={AdminLoginScreen}
                          />
                          <Route
                            path={PUBLIC_ROUTES.PAGE}
                            component={ExternalReaderScreen}
                          />
                          <Route
                            path={PUBLIC_ROUTES.READINGLIST}
                            component={ReadingListScreen}
                          />
                          <Route
                            path={PUBLIC_ROUTES.CATEGORY}
                            component={CategoryScreen}
                          />
                          <Route
                            path={PUBLIC_ROUTES.CATEGORIES}
                            component={CategoriesScreen}
                          />
                          <Route
                            path={PUBLIC_ROUTES.STARTBOOK}
                            component={ExternalStartBookScreen}
                          />
                          <Route
                            path={PUBLIC_ROUTES.FINISHBOOK}
                            component={ExternalFinishBookScreen}
                          />
                          <Route
                            path={PUBLIC_ROUTES.BOOK}
                            component={ExternalReaderScreen}
                          />
                          <Route
                            path={PUBLIC_ROUTES.BOOKS}
                            component={ExternalListScreen}
                          />
                          <PrivateRoute
                            exact
                            path={PRIVATE_ROUTES.ADD_PROFILE.path}
                            featureFlagIds={[FEATURE_IDS.ADD_PROFILES]}
                            render={() => <AddProfileScreen />}
                          />{' '}
                          <PrivateRoute
                            path={PRIVATE_ROUTES.ACCOUNT.path}
                            render={() => <AccountSettings />}
                          />
                          <PrivateRoute
                            path={PRIVATE_ROUTES.ADD_CHILD.path}
                            featureFlagIds={[FEATURE_IDS.CUSTOMER_PORTAL_SUB]}
                            render={() => <AddChildScreen />}
                          />
                          <PrivateRoute
                            path={PRIVATE_ROUTES.REMOVE_CHILD.path}
                            featureFlagIds={[
                              FEATURE_IDS.ALLOW_REMOVE_PROFILE,
                              FEATURE_IDS.CUSTOMER_PORTAL_SUB,
                            ]}
                            render={() => <RemoveChildScreen />}
                          />
                          <PageRoute
                            exact
                            path={PUBLIC_ROUTES.ROOT}
                            render={() => <ParentLoginScreen />}
                          />
                          <PageRoute
                            exact
                            path={PUBLIC_ROUTES.EMAIL_LOGIN}
                            render={() => (
                              <ParentLoginScreen
                                initialLoginStep={LoginStep.EmailStep}
                              />
                            )}
                          />
                          <PageRoute
                            exact
                            path={PUBLIC_ROUTES.PHONE_LOGIN}
                            render={() => (
                              <ParentLoginScreen
                                initialLoginStep={LoginStep.PhoneStep}
                              />
                            )}
                          />
                          <Route
                            path="*"
                            component={() => (
                              <Error error={ErrorMessage.NotFound} />
                            )}
                          />
                        </Switch>
                      </QueryParamProvider>
                    </AnalyticsProvider>
                  </Router>
                </Container>
              </ApolloProvider>
            </LocalizationContext.Provider>
          </FirebaseAuthProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </GrowthBookProvider>
  );
};

export default React.memo(App);
