import React from 'react';
import {
  Dialog as MaterialDialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
} from '@mui/material';
import {ReactComponent as Close} from 'shared/assets/icons/CloseIcon.svg';
import {turquoise300} from 'shared/styles/palette';

type Props = {
  open: boolean;
  onClose: () => void;
  title: string;
};

export const Dialog: React.FC<Props> = ({open, onClose, title, children}) => {
  return (
    <MaterialDialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      PaperProps={{
        sx: {
          borderRadius: '16px',
          padding: '16px',
          background: turquoise300,
          maxWidth: '358px',
        },
      }}
      slotProps={{
        backdrop: {sx: {backgroundColor: 'rgba(51, 92, 110, .4)'}},
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginBottom: '16px',
        }}>
        <IconButton
          size="small"
          disableRipple
          sx={{
            padding: 0,
            '&:hover': {
              background: 'none',
            },
          }}
          onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <DialogTitle
        sx={{
          fontWeight: '700',
          fontSize: '22px',
          padding: 0,
          marginBottom: '16px',
          textAlign: 'center',
          lineHeight: '1.4',
          letterSpacing: '-.066px',
        }}>
        {title}
      </DialogTitle>
      <DialogContent sx={{padding: 0}}>
        <Box sx={{width: '100%'}}>{children}</Box>
      </DialogContent>
    </MaterialDialog>
  );
};
