import React, {useEffect} from 'react';
import {Box, Typography, Link} from '@mui/material';
import {ReactComponent as ErrorRobotImage} from 'shared/assets/ErrorRobotImage.svg';
import {gray900, orange50, turquoise900} from 'shared/styles/palette';
import {supportPhoneNumber} from 'customerPortal/constants/constants';

export const ErrorCard = () => {
  useEffect(() => {
    window.analytics.track('ParentDashboard DashboardErrorSeen');
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        padding: '24px 16px',
        borderRadius: '16px',
        maxWidth: '400px',
        width: '100%',
        backgroundColor: orange50,
      }}>
      <ErrorRobotImage />
      <Typography
        variant="body1"
        sx={{
          textAlign: 'center',
          fontWeight: '600',
          lineHeight: '1.6',
          letterSpacing: '-0.048px',
          color: gray900,
        }}>
        Something went wrong and we couldn’t update your progress report. Try
        again later or text us at{' '}
        <Link
          href="sms:+14152148119"
          sx={{
            fontWeight: '700',
            color: turquoise900,
            textDecoration: 'none',
          }}>
          {supportPhoneNumber}
        </Link>
      </Typography>
    </Box>
  );
};
