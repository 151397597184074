import {Bundle} from './subscriptionPreview';

export interface User {
  name?: string;
  email?: string;
  phoneNumber?: string;
  countryCode?: string;
}

export interface Customer {
  userDetails: User;
  referralCode?: string;
  id: string;
  productType: string;
  billingSource?: string;
  tags?: string[];
}

export interface CustomerSubscription {
  title: string;
  name: Bundle | string;
  description: string;
  nextCycleDate?: string;
  price: number;
}

export enum BillingSource {
  Stripe = 'Stripe',
  ReveneuCat = 'RevenueCat',
}

export function isInAppCustomer(billingSource?: string) {
  if (!billingSource) {
    return false;
  }

  return billingSource === BillingSource.ReveneuCat;
}

const USCountryCode = 'US';
export function isUSCustomer(countryCode?: string, phoneNumber?: string) {
  if (!countryCode) {
    return phoneNumber?.startsWith('+1');
  }

  return countryCode === USCountryCode;
}
