import React, {useEffect} from 'react';
import {List} from '@mui/material';
import {useReadingRecord} from '../ProgressReportScreen.service';
import moment from 'moment';
import ReadingRecordItem from '../ReadingRecord/ReadingRecordItem';
import {BookStatus} from '../types';
import {useParams} from 'react-router-dom';
import Section from 'customerPortal/components/Section';
import {useProfiles} from '../../ProfilesScreen/EditProfile/EditProfileScreen.service';
import {ReadingRecordHeader} from './ReadingRecord.header';

export const ReadingRecords = () => {
  const {profileId} = useParams<{profileId: string}>();

  const {data, loading: profilesLoading, error: profilesError} = useProfiles();
  const readingRecords = useReadingRecord(profileId, true);

  useEffect(() => {
    window.analytics.track('ParentDashboard ReadingRecordsViewed');
  }, []);

  const selectedProfile = data?.profiles?.find(({id}) => id === profileId);

  const isLoading = profilesLoading || readingRecords.loading;
  const error = profilesError || readingRecords.error;

  return (
    <Section
      titleSection={<ReadingRecordHeader selectedProfile={selectedProfile} />}
      error={error?.message || ''}
      loading={isLoading}>
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '16px',
          padding: 0,
          paddingTop: '16px',
          marginBottom: '20px',
        }}>
        {readingRecords?.data?.map((record, index) => (
          <ReadingRecordItem
            key={index}
            bookTitle={record.bookTitle}
            status={record.event as BookStatus}
            duration={`${record.minutes} Min`}
            date={moment(record.timestamp).format('MMM DD')}
            image={record.coverPhoto}
          />
        ))}
      </List>
    </Section>
  );
};
