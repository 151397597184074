import React, {useEffect, useRef, useState} from 'react';
import {Box, CircularProgress, useMediaQuery, useTheme} from '@mui/material';
import {MobileDashboard} from './MobileDashboard/MobileDashboard';
import {DesktopDashboard} from './DesktopDashboard/DesktopDashboard';
import {ErrorCard} from './components/ErrorCard';
import {
  deviceType,
  generateReportPdf,
  isDesktop,
  isEarlyReadingLevel,
  shareReport,
  useCurriculumProgressReport,
  useLifeTimeAchievementsReport,
  useProfileQuestsByID,
  useReadingRate,
  useReadingRecord,
  useWeeklyAchievementsReport,
} from './ProgressReportScreen.service';
import {EmptyState} from './EmptyState/EmptyState';
import {useProfiles} from '../ProfilesScreen/EditProfile/EditProfileScreen.service';
import {Profile} from 'shared/types/profile';
import Alert from 'shared/components/common/Alert/Alert';
import {ProfileDropdown} from './components/ProfileDropdown/ProfileDropdown';
import {SupportContact} from './components/SupportContact';
import {collectQueryParams} from 'shared/utils/queryParams';
import featureFlags, {FEATURE_IDS} from 'shared/utils/featureFlags';
import {ShareOptionsDialog} from './components/Dialog/ShareOptionsDialog';
import {FeaturePreviewDialog} from './components/Dialog/FeaturePreviewDialog';
import {ShareDialog} from './components/Dialog/ShareDialog';
import {ShareReport} from './ShareReport/ShareReport';
import {PdfReportView} from './components/PdfReportView';
import {CreateStoryCard} from '../CreateStoryScreen/Components/CreateStoryCard';
import {Customer} from 'shared/types/customer';
import AnalyticsService from 'analytics/Analytics.service';

type Props = {
  customer?: Customer;
};

export const ProgressReportScreen: React.FC<Props> = ({customer}) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const [selectedProfile, setSelectedProfile] = useState<Profile | null>(null);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [sharePreviewOpen, setSharePreviewOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const [sharingReport, setSharingReport] = useState(false);

  const reportRef = useRef<HTMLDivElement | null>(null);

  const {
    data: profilesData,
    loading: profilesLoading,
    error: profilesError,
  } = useProfiles();

  const profiles = profilesData?.profiles;

  useEffect(() => {
    const device = deviceType();
    const queryParams = collectQueryParams();
    window.analytics.track('ParentDashboard DashboardViewed', {
      device,
      ...queryParams,
    });
    if (profiles?.length && !selectedProfile) {
      setSelectedProfile(profiles[0]);
    }
  }, [profiles, selectedProfile]);

  const {
    id: profileId = '',
    name: profileName = '',
    readingLevel = '',
  } = selectedProfile || {};

  const handleShareClick = async () => {
    setSharingReport(true);
    await new Promise(resolve => setTimeout(resolve, 100));
    const pdf = await generateReportPdf(reportRef);
    if (!pdf) return;

    const userAgent = navigator.userAgent.toLocaleLowerCase();
    const fileName = `Ello-Progress-Report_${profileName}.pdf`;

    if (isDesktop(userAgent)) {
      pdf.save(fileName);
      setSharingReport(false);
      setShareModalOpen(true);
      return;
    }
    shareReport(pdf, fileName);
    setSharingReport(false);
  };

  const {
    data,
    loading: questsLoading,
    error: questsError,
  } = useProfileQuestsByID(profileId, {
    status: 'completed',
  });

  const hasQuests = !!data?.length;

  const lifeTimeAchievements = useLifeTimeAchievementsReport(profileId);

  const weeklyAchievements = useWeeklyAchievementsReport(profileId, hasQuests);

  const readingRecords = useReadingRecord(profileId, hasQuests);

  const curriculumProgress = useCurriculumProgressReport(profileId, hasQuests);

  const readingRate = useReadingRate(profileId, readingLevel);

  const hasError =
    lifeTimeAchievements.error ||
    weeklyAchievements.error ||
    readingRecords.error ||
    curriculumProgress.error ||
    readingRate.error;

  const fetchError = profilesError || questsError || lifeTimeAchievements.error;

  const isLoading =
    profilesLoading || questsLoading || lifeTimeAchievements.loading;

  const booksRead = lifeTimeAchievements?.data?.books ?? 0;
  const showEmptyState = !hasQuests || booksRead === 0;

  if (isLoading) {
    return <CircularProgress sx={{margin: '50px auto', display: 'block'}} />;
  }

  if (fetchError) {
    return (
      <Alert
        error={fetchError?.message || 'Error loading data'}
        autoHide={false}
      />
    );
  }

  const showReadingRate =
    !isEarlyReadingLevel(readingLevel) &&
    featureFlags.isOn(FEATURE_IDS.PARENT_DASHBOARD_READING_RATE);

  const reportsSharingEnabled =
    !showEmptyState && featureFlags.isOn(FEATURE_IDS.PARENT_DASHBOARD_SHARING);

  const shareAsPDFEnabled =
    !showEmptyState &&
    featureFlags.isOn(FEATURE_IDS.PARENT_DASHBOARD_SHARING_PDF);

  const createStoryEnabled = featureFlags.isOn(FEATURE_IDS.CREATE_STORY_SCREEN);

  const desktopDashboard = (
    <DesktopDashboard
      profileName={profileName}
      profileId={profileId}
      weeklyAchievements={weeklyAchievements}
      readingRecords={readingRecords}
      curriculumProgress={curriculumProgress}
      readingRate={readingRate}
      showReadingRate={showReadingRate}
      lifeTimeAchievements={lifeTimeAchievements}
    />
  );

  return (
    <Box
      sx={{
        paddingBottom: '64px',
        [theme.breakpoints.up('lg')]: {
          paddingBottom: '80px',
        },
      }}>
      <ProfileDropdown
        profiles={profiles}
        selectedProfile={selectedProfile}
        setSelectedProfile={setSelectedProfile}
        onShareClick={() => {
          if (shareAsPDFEnabled) {
            handleShareClick();
            return;
          }
          setShareDialogOpen(true);
        }}
        reportsSharingEnabled={reportsSharingEnabled}
      />
      {showEmptyState ? (
        <EmptyState
          isMobileView={isMobileView}
          profileName={profileName}
          hasQuests={hasQuests}
          showReadingRate={showReadingRate}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            [theme.breakpoints.up('lg')]: {
              padding: '0 24px',
            },
          }}>
          {hasError && (
            <Box sx={{marginBottom: '16px'}}>
              <ErrorCard />
            </Box>
          )}
          {isMobileView ? (
            <MobileDashboard
              profileName={profileName}
              profileId={profileId}
              weeklyAchievements={weeklyAchievements}
              readingRecords={readingRecords}
              curriculumProgress={curriculumProgress}
              readingRate={readingRate}
              showReadingRate={showReadingRate}
              lifeTimeAchievements={lifeTimeAchievements}
            />
          ) : (
            desktopDashboard
          )}
          <PdfReportView ref={reportRef} profileName={profileName}>
            {desktopDashboard}
          </PdfReportView>
          {createStoryEnabled && (
            <Box
              sx={{
                margin: '0 auto',
                width: '100%',
                [theme.breakpoints.down('lg')]: {
                  maxWidth: '380px',
                },
              }}>
              <CreateStoryCard
                email={customer?.userDetails?.email ?? ''}
                onCtaClick={() =>
                  AnalyticsService.trackPersonalisedStoryCtaClicked(
                    'Progress Report Screen',
                  )
                }
              />
            </Box>
          )}
          {reportsSharingEnabled && (
            <ShareReport
              profileName={profileName}
              onPreviewOpen={() => setSharePreviewOpen(true)}
              onShare={handleShareClick}
              shareAsPDFEnabled={shareAsPDFEnabled}
              sharingReport={sharingReport}
            />
          )}
        </Box>
      )}
      {isMobileView && <SupportContact profileName={profileName} />}
      <ShareOptionsDialog
        open={shareDialogOpen}
        onClose={() => setShareDialogOpen(false)}
        onPreviewOpen={() => setSharePreviewOpen(true)}
      />
      <FeaturePreviewDialog
        open={sharePreviewOpen}
        onClose={() => setSharePreviewOpen(false)}
      />
      <ShareDialog
        open={shareModalOpen}
        onClose={() => setShareModalOpen(false)}
        profileName={profileName}
      />
    </Box>
  );
};
