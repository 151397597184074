import React, {useState} from 'react';
import {Popper, Box, Paper, Typography} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import {gray, gray700, turquoise900} from 'shared/styles/palette';
import {ReactComponent as Polygon2} from 'shared/assets/icons/polygon2.svg';

export const Tooltip = () => {
  const [arrowRef, setArrowRef] = useState(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Box
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        component="span"
        sx={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
        <InfoIcon sx={{color: turquoise900}} />
      </Box>

      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        modifiers={[
          {
            name: 'flip',
            enabled: false,
          },
          {
            name: 'offset',
            options: {offset: [-60, 15]},
          },
          {
            name: 'arrow',
            options: {element: arrowRef},
          },
        ]}>
        <Paper
          sx={{
            position: 'relative',
            padding: '16px',
            borderRadius: '8px',
            maxWidth: '296px',
            bgcolor: gray,
          }}>
          <Box
            ref={setArrowRef}
            sx={{
              position: 'absolute',
              top: '-15px',
            }}>
            <Polygon2 />
          </Box>
          <Typography
            variant="body2"
            sx={{color: gray700, lineHeight: '1.4', letterSpacing: '-0.112px'}}>
            Reading rate is just one of many ways to understand your child's
            overall reading development. Think of it like your pediatrician
            taking your child's temperature at a checkup. Measuring words read
            per minute is one tool to monitor your child's reading “health” and
            flag when something seems off, but it is not the only signal of
            their reading ability.
          </Typography>
        </Paper>
      </Popper>
    </Box>
  );
};
