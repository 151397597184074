import {useMutation} from '@apollo/client';
import {Dialog} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import {FlowBanner} from 'customerPortal/components/Flow';
import useMobileLayout from 'customerPortal/utils/useMobileLayout';
import React, {useEffect, useRef, useState} from 'react';
import {ReactComponent as IconCross} from 'shared/assets/icons/iconCross.svg';
import Button, {ButtonType} from 'shared/components/common/Button';
import * as palette from 'shared/styles/palette';
import {appleBillingManagementUrl} from 'shared/utils/appBilling';
import {toUSDateFormat} from 'shared/utils/dateUtils';
import {supportsDigital} from 'shared/utils/productType';
import CancellationConfirmation from './CancellationConfirmation/CancellationConfirmation';
import CancellationConfirmed from './CancellationConfirmed/CancellationConfirmed';
import CancellationFeedback from './CancellationFeedback/CancellationFeedback';
import CustomizedCancellation from './CustomizedCancellationFlow/CustomizedCancellationFlow';
import {
  CANCEL_SUBSCRIPTION_MUTATION,
  DOWNGRADE_SUBSCRIPTION_MUTATION,
} from './CancellationForm.query';
import {
  cancelSubscriptionWithMutation,
  downgradeSubscriptionWithMutation,
  isRevenueCat,
  parseCancellationInput,
  trackCancellation,
} from './CancellationForm.service';
import styles from './CancellationForm.styles';
import CancellationReason from './CancellationReason/CancellationReason';
import {
  digitalCancellationReasons,
  physicalCancellationReasons,
} from './constants';
import {CancellationFormState, CancellationInput} from './types';
import {customizedFlowsMapping} from './CustomizedCancellationFlow/CustomizedFlowsMapping';
import featureFlags, {FEATURE_IDS} from 'shared/utils/featureFlags';
import CancellationOfferMonthly from './CancellationOfferMonthly/CancellationOfferMonthly';
import CancellationSwitchedMonthly from './CancellationSwitchedMonthly/CancellationSwitchedMonthly';

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  subscriptionPeriodEnd?: number;
  productType: string | undefined;
  subscriptionStatus: string;
  profileNames: string[];
  billingSource: string;
  toOfferMonthlyDowngrade: boolean;
  onClose: () => void;
}

const CancellationForm = ({
  classes,
  open,
  subscriptionPeriodEnd,
  productType,
  subscriptionStatus,
  profileNames,
  billingSource,
  toOfferMonthlyDowngrade,
  onClose,
}: Props) => {
  const isMobile = useMobileLayout();

  const initState = CancellationFormState.SelectReasonCategory;

  const [state, setState] = useState<CancellationFormState>(initState);
  const stateHistory = useRef<CancellationFormState[]>([]);
  const [cancellationDetails, setCancellationDetails] =
    useState<CancellationInput>({
      cancelCategory: '',
      cancelDetail: '',
      feedback: '',
    });
  const [isLoading, setIsLoading] = useState(false);
  const [hideBackButton, setHideBackButton] = useState(false);
  const [cancelSubscriptionMutation] = useMutation(
    CANCEL_SUBSCRIPTION_MUTATION,
  );
  const [downgradeSubscriptionMutation] = useMutation(
    DOWNGRADE_SUBSCRIPTION_MUTATION,
  );
  const cancel = cancelSubscriptionWithMutation(cancelSubscriptionMutation);
  const downgradeToMonthly = downgradeSubscriptionWithMutation(
    downgradeSubscriptionMutation,
  );
  const subscriptionEndDate =
    subscriptionPeriodEnd && toUSDateFormat(subscriptionPeriodEnd);
  const cancellationReasonChoices = supportsDigital(productType)
    ? digitalCancellationReasons
    : physicalCancellationReasons;

  useEffect(() => {
    if (open) {
      setState(initState);
      stateHistory.current = [];
      setCancellationDetails({
        cancelCategory: '',
        cancelDetail: '',
        feedback: '',
      });
    }
  }, [open, initState]);

  const transition = (nextState: CancellationFormState) => {
    stateHistory.current.push(state);
    setState(nextState);
  };

  const goBack = () => {
    const prevState = stateHistory.current.pop();
    if (!!prevState) {
      setState(prevState);
    } else {
      onClose();
    }
  };

  const onSkip = () => {
    switch (state) {
      case CancellationFormState.SelectReasonCategory:
      case CancellationFormState.SelectReasonDetail:
        transition(CancellationFormState.FeedbackForm);
        break;
      case CancellationFormState.FeedbackForm:
        transition(CancellationFormState.CancellationConfirmation);
        break;
      default:
        console.error(`Cannot perform skip from ${state}`);
        break;
    }
  };

  const cancelSubscription = async () => {
    const input = parseCancellationInput(
      cancellationReasonChoices,
      cancellationDetails,
    );

    setIsLoading(true);
    await cancel(input);
    setIsLoading(false);
  };

  const confirmCancellation = async () => {
    if (isRevenueCat(billingSource)) {
      window.location.assign(appleBillingManagementUrl);
      return;
    }

    window.location.reload();
  };

  const switchToMonthly = async () => {
    setIsLoading(true);
    await downgradeToMonthly();
    setIsLoading(false);

    window.location.reload();
  };

  const userSelectedOther = (resp: any) => {
    return ['other'].includes(resp);
  };

  const hasCustomizedCancellationFlow = (
    cancelCategory: string,
    cancelDetail = 'default',
  ) => {
    const categoryMapping = customizedFlowsMapping[cancelCategory];
    if (!categoryMapping) {
      return false;
    }

    const flowDetail = categoryMapping[cancelDetail];

    if (
      flowDetail?.billingSource &&
      flowDetail.billingSource !== billingSource
    ) {
      return false;
    }

    const isFeatureEnabled = featureFlags.isOn(
      FEATURE_IDS.CUSTOMIZED_CANCELLATION_FLOW,
    );

    return !!flowDetail && isFeatureEnabled;
  };

  const onNext = async (resp: any = '') => {
    switch (state) {
      case CancellationFormState.SelectReasonCategory:
        if (userSelectedOther(resp)) {
          transition(CancellationFormState.FeedbackForm);
          break;
        }
        if (hasCustomizedCancellationFlow(resp)) {
          transition(CancellationFormState.CustomizedCancellation);
          break;
        }
        transition(CancellationFormState.SelectReasonDetail);
        break;
      case CancellationFormState.SelectReasonDetail:
        if (
          hasCustomizedCancellationFlow(
            cancellationDetails.cancelCategory,
            resp,
          )
        ) {
          transition(CancellationFormState.CustomizedCancellation);
          break;
        }
        transition(CancellationFormState.FeedbackForm);
        break;
      case CancellationFormState.CustomizedCancellation:
        transition(CancellationFormState.FeedbackForm);
        break;
      case CancellationFormState.FeedbackForm:
        if (toOfferMonthlyDowngrade) {
          trackCancellation(
            cancellationReasonChoices,
            cancellationDetails,
            billingSource,
            subscriptionStatus,
          );
          transition(CancellationFormState.CancellationOfferMonthly);
          break;
        }
        transition(CancellationFormState.CancellationConfirmation);
        break;
      case CancellationFormState.CancellationOfferMonthly:
        transition(CancellationFormState.CancellationSwitchedMonthly);
        break;
      case CancellationFormState.CancellationConfirmation:
        trackCancellation(
          cancellationReasonChoices,
          cancellationDetails,
          billingSource,
          subscriptionStatus,
        );
        await cancelSubscription();
        transition(CancellationFormState.CancellationConfirmed);
        break;
      case CancellationFormState.CancellationSwitchedMonthly:
        await switchToMonthly();
        break;
      case CancellationFormState.CancellationConfirmed:
        await confirmCancellation();
        break;
      default:
        console.error(`Cannot find next state for ${state}`);
        break;
    }
  };

  const onTapOverlay = () => {
    if (state !== CancellationFormState.CancellationConfirmed) {
      onClose();
    }
  };

  const hideBackBtn =
    state === CancellationFormState.CancellationConfirmed || hideBackButton;

  return (
    <>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: isMobile ? 0 : 16,
            maxWidth: '760px',
            width: '100%',
          },
        }}
        BackdropProps={{
          style: {backgroundColor: palette.blue500, opacity: 0.4},
        }}
        fullScreen={isMobile}
        fullWidth={true}
        open={open}
        onClose={onTapOverlay}>
        <div className={classes.container}>
          {isMobile ? (
            <>
              <FlowBanner onBack={goBack} hideBackBtn={hideBackBtn} />
            </>
          ) : (
            <>
              {state !== CancellationFormState.CancellationConfirmed && (
                <Button
                  variant={ButtonType.Icon}
                  onClick={onClose}
                  aria-label="Close"
                  className={classes.closeButton}>
                  <IconCross />
                </Button>
              )}
            </>
          )}
          {isLoading ? (
            <CircularProgress
              style={{
                margin: isMobile ? '40vh auto' : '200px auto',
                display: 'block',
              }}
            />
          ) : (
            <>
              {state === CancellationFormState.SelectReasonCategory && (
                <CancellationReason
                  choices={cancellationReasonChoices}
                  subscriptionStatus={subscriptionStatus}
                  onConfirm={async option => {
                    setCancellationDetails({
                      ...cancellationDetails,
                      cancelCategory: option,
                    });
                    await onNext(option);
                  }}
                  onSkip={onSkip}
                  hideDesktopBackButton
                />
              )}
              {state === CancellationFormState.SelectReasonDetail && (
                <CancellationReason
                  choices={
                    cancellationReasonChoices[
                      cancellationDetails.cancelCategory
                    ].subchoices
                  }
                  subscriptionStatus={subscriptionStatus}
                  onConfirm={async option => {
                    setCancellationDetails({
                      ...cancellationDetails,
                      cancelDetail: option,
                    });
                    await onNext(option);
                  }}
                  onSkip={onSkip}
                  onBack={goBack}
                  titleText={'Help Ello improve by specifying the problem'}
                />
              )}
              {state === CancellationFormState.CustomizedCancellation && (
                <CustomizedCancellation
                  cancelCategory={cancellationDetails.cancelCategory}
                  cancelDetail={cancellationDetails.cancelDetail}
                  onConfirm={onNext}
                  setHideBackButton={setHideBackButton}
                  onClose={onClose}
                />
              )}
              {state === CancellationFormState.FeedbackForm && (
                <CancellationFeedback
                  onConfirm={async input => {
                    setCancellationDetails({
                      ...cancellationDetails,
                      feedback: input,
                    });
                    await onNext();
                  }}
                  onSkip={async () => {
                    if (toOfferMonthlyDowngrade) {
                      trackCancellation(
                        cancellationReasonChoices,
                        cancellationDetails,
                        billingSource,
                        subscriptionStatus,
                      );
                      transition(
                        CancellationFormState.CancellationOfferMonthly,
                      );
                      return;
                    }
                    onSkip();
                    return;
                  }}
                  onBack={goBack}
                />
              )}
              {state === CancellationFormState.CancellationOfferMonthly && (
                <CancellationOfferMonthly
                  onConfirm={onNext}
                  onBack={async () => {
                    transition(CancellationFormState.CancellationConfirmation);
                    return;
                  }}
                />
              )}
              {state === CancellationFormState.CancellationSwitchedMonthly && (
                <CancellationSwitchedMonthly
                  endDate={subscriptionEndDate?.toString()}
                  onConfirm={onNext}
                />
              )}
              {state === CancellationFormState.CancellationConfirmation && (
                <CancellationConfirmation
                  onConfirm={onNext}
                  onBack={onClose}
                  endDate={subscriptionEndDate?.toString()}
                  subscriptionStatus={subscriptionStatus}
                  profileNames={profileNames}
                  billingSource={billingSource}
                />
              )}
              {state === CancellationFormState.CancellationConfirmed && (
                <CancellationConfirmed
                  billingSource={billingSource}
                  onConfirm={onNext}
                  onBack={goBack}
                  hideDesktopBackButton
                />
              )}
            </>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(CancellationForm);
