import {Dialog} from '@mui/material';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import {FlowBanner} from 'customerPortal/components/Flow';
import useMobileLayout from 'customerPortal/utils/useMobileLayout';
import React, {useState} from 'react';
import {ReactComponent as IconCross} from 'shared/assets/icons/iconCross.svg';
import Button, {ButtonType} from 'shared/components/common/Button';
import * as palette from 'shared/styles/palette';
import styles from './DeleteAccountForm.styles';
import DeleteAccountConfirmation from './DeleteAccountConfirmation/DeleteAccountConfirmation';
import DeleteAccountConfirmed from './DeleteAccountConfirmed/DeleteAccountConfirmed';
import {sendSlackMessage, SlackMessageTypes} from 'shared/utils/slack';

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  email: string;
  onClose: () => void;
}

const DeleteAccountForm = ({classes, open, email, onClose}: Props) => {
  const isMobile = useMobileLayout();

  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const onTapOverlay = () => {
    onClose();
    setDeleteConfirmed(false);
  };

  const onNext = () => {
    setDeleteConfirmed(true);
    const message = `${email} has requested to delete their account`;
    console.log(`Sending slack message: ${message}`);

    sendSlackMessage(SlackMessageTypes.DeleteAccount, message);
  };

  const close = () => () => {
    onClose();
    setDeleteConfirmed(false);
  };

  return (
    <>
      <Dialog
        PaperProps={{
          style: {borderRadius: isMobile ? 0 : 16},
        }}
        BackdropProps={{
          style: {backgroundColor: palette.blue500, opacity: 0.4},
        }}
        fullScreen={isMobile}
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={onTapOverlay}>
        <div className={classes.container}>
          {isMobile ? (
            <>
              <FlowBanner onBack={onClose} hideBackBtn={false} />
            </>
          ) : (
            <>
              <Button
                variant={ButtonType.Icon}
                onClick={close()}
                aria-label="Close"
                className={classes.closeButton}>
                <IconCross />
              </Button>
            </>
          )}
          {
            <>
              {deleteConfirmed ? (
                <DeleteAccountConfirmed onConfirm={close()} />
              ) : (
                <DeleteAccountConfirmation
                  onConfirm={onNext}
                  onBack={close()}
                />
              )}
            </>
          }
        </div>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(DeleteAccountForm);
