import React, {useEffect} from 'react';
import Section from 'customerPortal/components/Section';
import {CreateStoryCard} from './Components/CreateStoryCard';
import {Customer} from 'shared/types/customer';
import {Box, useTheme} from '@mui/material';
import AnalyticsService from 'analytics/Analytics.service';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';

type Props = {
  customer?: Customer;
};

export const CreateStoryScreen: React.FC<Props> = ({customer}) => {
  const theme = useTheme();

  useEffect(() => {
    AnalyticsService.trackCreateStoryScreenViewed();
  }, []);

  const handleCtaClick = () => {
    AnalyticsService.trackPersonalisedStoryCtaClicked('Create Story Screen');
  };

  return (
    <Section title="Create a story">
      <Box
        sx={{
          width: '100%',
          pt: '24px',
          margin: '0 auto',
          [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
            maxWidth: '380px',
          },
        }}>
        <CreateStoryCard
          email={customer?.userDetails?.email || ''}
          onCtaClick={handleCtaClick}
        />
      </Box>
    </Section>
  );
};
