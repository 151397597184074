import React from 'react';
import {ProgressCard} from '../components/ProgressCard/ProgressCard';
import {ReactComponent as ShareProgressCardIcon} from 'shared/assets/icons/ShareProgressCardIcon.svg';
import {gray700, turquoise300} from 'shared/styles/palette';
import {Stack, Typography, useTheme} from '@mui/material';
import {Button} from '../components/Button';
import {deviceType} from '../ProgressReportScreen.service';

type Props = {
  profileName: string;
  onPreviewOpen: () => void;
  onShare: () => void;
  shareAsPDFEnabled?: boolean;
  sharingReport?: boolean;
};

export const ShareReport: React.FC<Props> = ({
  profileName,
  onPreviewOpen,
  onShare,
  shareAsPDFEnabled,
  sharingReport,
}) => {
  const theme = useTheme();

  const handleShareClick = () => {
    const device = deviceType();
    window.analytics.track('ParentDashboard CardShareButtonClicked', {
      device,
    });

    if (shareAsPDFEnabled) {
      onShare();
      return;
    }
    onPreviewOpen();
  };

  return (
    <ProgressCard
      sx={{
        background: turquoise300,
        [theme.breakpoints.up('lg')]: {
          maxWidth: 'initial',
        },
      }}
      icon={<ShareProgressCardIcon />}
      title="Share with your teacher">
      <Stack alignItems="center">
        <Typography
          sx={{
            color: gray700,
            textAlign: 'center',
            lineHeight: '1.6',
            letterSpacing: '-0.048px',
            marginBottom: '24px',
          }}>
          Show {profileName}'s teacher how much they've progressed with their
          reading
        </Typography>
        <Button
          sx={{
            [theme.breakpoints.up('sm')]: {
              width: 'fit-content',
            },
          }}
          label="Share Progress"
          onClick={handleShareClick}
          isLoading={sharingReport}
        />
      </Stack>
    </ProgressCard>
  );
};
