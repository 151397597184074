import {Box, Typography} from '@mui/material';
import React from 'react';
import {gray700} from 'shared/styles/palette';

type Props = {
  icon: React.ReactNode;
  label: string;
};

export const CheckListItem: React.FC<Props> = ({icon, label}) => (
  <Box sx={{display: 'flex', alignItems: 'center', gap: '8px'}}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '35px',
        flexShrink: 0,
      }}>
      {icon}
    </Box>
    <Typography
      variant="body2"
      sx={{
        color: gray700,
        fontSize: '1rem',
        lineHeight: '1.6',
        letterSpacing: '-0.12px',
      }}>
      {label}
    </Typography>
  </Box>
);
