import makeStyles from '@mui/styles/makeStyles';
import {Theme} from '@mui/material/styles';
import {
  BORDER_RADIUS,
  LAPTOP_BREAKPOINT,
  TABLET_BREAKPOINT,
} from 'shared/styles/breakpoints';
import {
  blue500,
  gray,
  gray500,
  gray700,
  gray900,
  orange500,
  teal500,
  turquoise900,
  white,
} from 'shared/styles/palette';

export const subscriptionInfoStyles = makeStyles((theme: Theme) => ({
  name: {
    fontWeight: 700,
    fontSize: '1.625rem',
    color: gray900,
    margin: `${theme.spacing(2)} 0`,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      fontSize: '1rem',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: '14px',
    marginBottom: 2,
  },
  desc: {
    flex: 1,
  },
  planSubtitle: {
    color: gray700,
    fontWeight: 400,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      fontWeight: 500,
      fontSize: `.875rem`,
    },
  },
  icon: {
    maxWidth: '200px',
    [theme.breakpoints.down(LAPTOP_BREAKPOINT)]: {
      width: '74px',
      height: '62px',
    },
  },
  inactiveIcon: {
    filter: 'grayscale(100%)',
  },
}));

export const cardActionStyles = makeStyles((theme: Theme) => ({
  container: {
    borderRadius: BORDER_RADIUS,
    marginTop: theme.spacing(2),
    '&:hover': {
      backgroundColor: gray,
    },
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      marginTop: theme.spacing(3),
    },
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    },
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      alignItems: 'flex-start',
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  cardIcon: {
    minWidth: `50px`,
    alignSelf: 'baseline',
    marginRight: theme.spacing(1),
  },
  arrow: {
    minWidth: `30px`,
  },
  titleContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  chip: {
    backgroundColor: orange500,
    color: white,
    fontWeight: 700,
    fontSize: `0.75rem`,
    padding: `0 ${theme.spacing(0.5)}`,
  },
  title: {
    fontWeight: 700,
    color: gray900,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      fontSize: '1rem',
      display: 'inline-block',
      marginRight: theme.spacing(1),
    },
  },
  actionSubtitle: {
    fontWeight: 400,
    fontSize: '0.875rem',
    color: gray700,
  },
  altSubtitle: {
    display: 'inline-block',
    fontWeight: 500,
    fontSize: `0.812rem`,
    color: gray700,
  },
}));

export const accountSubscriptionStyles = makeStyles((theme: Theme) => ({
  subscriptionChip: {
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      marginLeft: `${theme.spacing(2)} `,
    },
    fontWeight: 700,
    fontSize: `0.75rem`,
  },
  activeSubscriptionChip: {
    color: teal500,
    borderColor: teal500,
  },
  inactiveSubscriptionChip: {
    color: orange500,
    borderColor: orange500,
  },
}));

export const blockResubscriptionModalStyles = makeStyles((theme: Theme) => ({
  box: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 900,
    width: '80vw',
    borderRadius: '1em',
    backgroundColor: white,
    padding: '2rem',
  },
  closeIconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  blockSubscriptionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '1rem 0rem',
  },
  header: {
    color: blue500,
    fontWeight: 800,
    fontSize: '2rem',
    lineHeight: '2.5rem',
    textAlign: 'center',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      marginBottom: theme.spacing(2),
    },
  },
  subttitle: {
    color: blue500,
    fontWeight: 500,
    fontSize: '1.2rem',
    lineHeight: '2rem',
    textAlign: 'center',
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      fontWeight: 400,
      color: gray500,
      lineHeight: '2rem',
    },
  },
  button: {
    backgroundColor: turquoise900,
    width: '100%',
    color: white,
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    borderRadius: '2rem',
    fontWeight: 800,
    fontSize: '1rem',
    textTransform: 'none',
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      width: '200px',
    },
  },
}));
