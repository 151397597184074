import React from 'react';
import Section from 'customerPortal/components/Section';
import DataList from 'customerPortal/components/DataList/DataList';
import {User} from 'shared/types/customer';
import {ApolloError} from '@apollo/client';
import {Customer} from '../AccountSubscription.types';
import {Box} from '@mui/material';

const FRIENDLY_KEYS: Record<keyof User, string> = {
  name: 'Parent Name',
  email: 'Email',
  phoneNumber: 'Phone Number',
  countryCode: '',
};

const getDetailsMapping = (userDetails: User): Record<string, string> => {
  return Object.entries(userDetails).reduce(
    (res: Record<string, string>, current: [string, string | null]) => {
      const friendlyKey = FRIENDLY_KEYS[current[0] as keyof User];

      if (!friendlyKey) return res;

      res[friendlyKey] = current[1] ?? '---';
      return res;
    },
    {},
  );
};

type Props = {
  loading?: boolean;
  error?: ApolloError | undefined;
  customerData?: Customer;
};

const AccountDetails: React.FC<Props> = ({loading, error, customerData}) => {
  return (
    <Section
      title="Account Details"
      loading={loading}
      error={error ? 'Unable to load account details' : ''}>
      <Box mb="44px">
        {customerData?.userDetails && (
          <DataList data={getDetailsMapping(customerData.userDetails)} />
        )}
      </Box>
    </Section>
  );
};

export default AccountDetails;
