import {useQuery} from '@apollo/client';
import {externalBookQuery, externalBooksQuery} from './Book.query';
import {
  BookFilter,
  BookReturn,
  BooksReturn,
  ExternalBookData,
  ExternalBooksData,
  Pagination,
} from './Book.type';

export function useExternalBook(bookId: string): BookReturn {
  const {data, error, loading} = useQuery<ExternalBookData>(externalBookQuery, {
    variables: {id: bookId},
  });

  const book = data?.externalBook;

  return {book, error, loading};
}

export function useExternalBooks(
  pagination: Pagination,
  filter: BookFilter,
): BooksReturn {
  const {data, error, loading} = useQuery<ExternalBooksData>(
    externalBooksQuery,
    {
      variables: {pagination, filter},
    },
  );

  const books = data?.externalBooks?.books;
  const pageInfo = data?.externalBooks?.pageInfo;

  return {books, pageInfo, error, loading};
}
