import {LevelSentence} from './AdjustReadingLevel.types';

export const levelSentences: LevelSentence[] = [
  {level: 'A2', sentence: 'The dog can hop to the log.'},
  {
    level: 'A5',
    sentence: 'The hen can sit on the six eggs and nap there.',
  },
  {
    level: 'B2',
    sentence: 'Did you see that ship? Chad says to his pal Josh.',
  },
  {
    level: 'B4',
    sentence: 'You can go see your pals when you are done with math.',
  },
  {
    level: 'C3',
    sentence: 'Spot the red hen lives down by the bushes with her flock.',
  },
  {
    level: 'D1',
    sentence: 'Rolf had a quiz and a test today, which felt like a lot to him.',
  },
  {
    level: 'E2',
    sentence:
      'I try to squish my bags into the back of the truck, but it does not fit.',
  },
  {
    level: 'F2',
    sentence:
      'When it gets hot out, Steve the snake likes to doze in the brown grass.',
  },
  {
    level: 'G2',
    sentence: 'I sing the tune at the top of my lungs, but it stinks.',
  },
  {
    level: 'H2',
    sentence:
      'I was trying to get a ticket to see my friend Hannah compete in the baking contest.',
  },
  {
    level: 'I2',
    sentence:
      'My brother Jayden sprinted inside just because a bee buzzed by while we were playing.',
  },
  {
    level: 'J2',
    sentence:
      'As the boat floated on the dark, foggy waters, the sailors inside made up tales to pass the time.',
  },
  {
    level: 'K1',
    sentence:
      'My sister wanted to stop me from buying the most costly bike in the store, but my mind was already made up.',
  },
  {
    level: 'L1',
    sentence:
      'The girl carefully picked a wildflower and took it back to her mother, who was setting up a picnic.',
  },
  {
    level: 'M1',
    sentence:
      'I grabbed my shoes, snatched a carrot from the fridge, yelled a quick goodbye to my mom, and was out the door in a minute.',
  },
  {
    level: 'N1',
    sentence:
      'The astronauts left their houses at dawn on Wednesday to get ready for the rocket launch later that week.',
  },
  {
    level: 'N3',
    sentence:
      'As Mr. Roberts drove down the road, he noticed a sign that read, "Lambs for Sale at Appletree Farm."',
  },
  {
    level: 'O1',
    sentence:
      "Recovering from hip surgery wasn't easy, but with support from his family and his doctors, my grandpa did a great job.",
  },
  {
    level: 'P1',
    sentence:
      "There is a myth that an explorer hid a magical crystal somewhere in a nearby mountain cave, but I don't believe it.",
  },
  {
    level: 'P3',
    sentence:
      'As the temperature in the jungle increased, the monkey thought he should try to find a shady spot in a tree to wait out the heat until he continued his adventure.',
  },
];
