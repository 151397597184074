import React, {FC, ReactNode, useEffect} from 'react';
import {useAuth} from '../shared/utils/providers/authProvider';
import {useCustomer, usePageViews} from './AnalyticsHook';

type Props = {
  children: ReactNode;
};

const disableAnalyticsTag = 'disable-analytics';
function disableAnalytics(tags: string[]): boolean {
  if (!tags) {
    return false;
  }

  return tags.includes(disableAnalyticsTag);
}

const AnalyticsProvider: FC<Props> = ({children}: Props) => {
  usePageViews();

  const auth = useAuth();
  const {customer} = useCustomer(!auth.user);

  useEffect(() => {
    if (!auth.user) {
      return;
    }

    if (!disableAnalytics(customer?.tags)) {
      return;
    }

    window.analytics.track = () => {};
    window.analytics.page = () => {};
    window.analytics.identify = () => {};
  }, [auth, customer]);

  return <>{children}</>;
};

export default AnalyticsProvider;
