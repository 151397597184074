import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {useHistory, useLocation} from 'react-router-dom';
import {useMutation} from '@apollo/client';
import styles from './AddProfileScreen.styles';
import {
  AddProfileStep,
  addProfileWithMutation,
  isDuplicateProfileName,
  nextAddProfileStep,
  previousAddProfileStep,
} from './AddProfileScreen.service';
import useMobileLayout from '../../../../utils/useMobileLayout';
import {addedProfileErrorType, addedProfileType} from '../ProfilesScreen.types';
import {Profile} from '../../../../../shared/types/profile';
import {CUSTOMER_ADD_PROFILE_MUTATION} from './AddProfileScreen.query';
import CircularProgress from '@mui/material/CircularProgress';

interface props extends WithStyles<typeof styles> {
  currentAddProfileStep: string;
  customerProfiles?: Profile[];
  profileBeingAdded: addedProfileType;
  setCurrentAddProfileStep: (currentStep: AddProfileStep) => void;
  setError: React.Dispatch<React.SetStateAction<addedProfileErrorType>>;
  setAddProfileError: (error: string) => void;
}

const AddProfileActions = ({
  classes,
  currentAddProfileStep,
  profileBeingAdded,
  customerProfiles,
  setCurrentAddProfileStep,
  setError,
  setAddProfileError,
}: props) => {
  const isMobile = useMobileLayout();

  const successFullyAddedProfile =
    currentAddProfileStep === AddProfileStep.SuccessfullyAddedProfile;
  const errorAddingProfile =
    currentAddProfileStep === AddProfileStep.ErrorWhenAddingProfile;
  const smallerCTAButton =
    !isMobile &&
    (currentAddProfileStep === AddProfileStep.AddDetails ||
      successFullyAddedProfile ||
      errorAddingProfile);

  const backStepHandler = () =>
    setCurrentAddProfileStep(previousAddProfileStep(currentAddProfileStep));
  const history = useHistory();
  const isFullScreen = successFullyAddedProfile || errorAddingProfile;

  const [customerAddProfileMutation, {loading}] = useMutation(
    CUSTOMER_ADD_PROFILE_MUTATION,
  );

  const addProfile = addProfileWithMutation(customerAddProfileMutation);
  const nextStepHandler = async () => {
    const addingProfileDetails =
      currentAddProfileStep === AddProfileStep.AddDetails;
    const thereIsEmptyField =
      !profileBeingAdded.name ||
      !profileBeingAdded.grade ||
      !profileBeingAdded.readingAbility;
    const isDuplicateName = isDuplicateProfileName(
      customerProfiles,
      profileBeingAdded.name,
    );
    const submittedDetailsFormWithErrors =
      addingProfileDetails && (thereIsEmptyField || isDuplicateName);

    if (submittedDetailsFormWithErrors) {
      setError({
        nameError: {
          required: !profileBeingAdded.name,
          duplicate: isDuplicateName,
        },
        gradeError: !profileBeingAdded.grade,
        readingAbilityError: !profileBeingAdded.readingAbility,
      });
      return;
    }

    let success;
    const selectedProfileCharacter =
      currentAddProfileStep === AddProfileStep.SelectProfileCharacter;
    if (selectedProfileCharacter) {
      try {
        await addProfile(profileBeingAdded);
        success = true;
      } catch (e) {
        if (e instanceof Error) {
          e.message && setAddProfileError(e.message);
        }
        success = false;
      }
    }

    const nextStep = nextAddProfileStep(currentAddProfileStep, success);
    setCurrentAddProfileStep(nextStep);
  };

  const buttonText = successFullyAddedProfile
    ? 'FINISH'
    : errorAddingProfile
    ? 'CLOSE'
    : 'NEXT';

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isFromApp = params.get('device') === 'mobile';

  const handleClick = () => {
    if (successFullyAddedProfile) {
      if (isFromApp) {
        window.location.href =
          'uniwebview://event?eventType=webView&data=close';
      } else {
        history.goBack();
      }
    } else if (errorAddingProfile) {
      backStepHandler();
    } else {
      nextStepHandler();
    }
  };

  return (
    <Box
      className={classes.actionsContainer}
      style={{
        justifyContent: isFullScreen ? 'center' : 'flex-start',
      }}>
      {currentAddProfileStep === AddProfileStep.SelectProfileCharacter && (
        <Button className={classes.backButton} onClick={backStepHandler}>
          Back
        </Button>
      )}

      <Button
        variant="contained"
        disabled={loading}
        className={classes.nextButton}
        style={{
          maxWidth: smallerCTAButton ? '300px' : '100%',
        }}
        onClick={handleClick}>
        {buttonText}

        {loading && (
          <CircularProgress
            size={20}
            style={{
              marginLeft: '1rem',
            }}
            color="inherit"
          />
        )}
      </Button>
    </Box>
  );
};

export default withStyles(styles)(AddProfileActions);
