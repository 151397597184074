import React from 'react';
import {Box, Stack} from '@mui/material';
import {ExpectedRangeLabel} from './ExpectedRangeLabel';

export const BoxWhiskerContainer: React.FC = ({children}) => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      height="91px"
      marginTop="12px">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          width: '100%',
        }}>
        {children}
      </Box>
      <ExpectedRangeLabel />
    </Stack>
  );
};
