import {Box, CardActionArea, Chip, Typography} from '@mui/material';
import Card from 'customerPortal/components/Card/Card';
import useMobileLayout from 'customerPortal/utils/useMobileLayout';
import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import {ReactComponent as IconChevron} from 'shared/assets/icons/iconChevron.svg';
import {cardActionStyles} from './AccountSubscription.styles';

type Props = {
  route?: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  subtitle: string | React.ReactNode;
  alternativeSubtitle?: string;
  tag?: string;
  onClick?: () => void;
  actionDisabled?: boolean;
};

export const ActionCard: FC<Props> = ({
  route,
  Icon,
  title,
  subtitle,
  alternativeSubtitle,
  tag,
  onClick = () => {},
  actionDisabled = false,
}: Props) => {
  const classes = cardActionStyles();
  const isMobile = useMobileLayout();

  const linkProps = !route ? {} : {component: Link, to: route};

  return (
    <CardActionArea
      className={classes.container}
      {...linkProps}
      onClick={onClick}
      disabled={actionDisabled}>
      <Card className={classes.card}>
        <div className={classes.cardContent}>
          <Box className={classes.iconContainer}>
            <Icon className={classes.cardIcon} />
          </Box>
          <div>
            <span className={classes.titleContainer}>
              <Typography component="h1" variant="h6" className={classes.title}>
                {title}
              </Typography>
              {tag && (
                <Chip size="small" label={tag} className={classes.chip} />
              )}
            </span>
            {isMobile ? (
              <>
                {alternativeSubtitle && (
                  <Typography className={classes.altSubtitle}>
                    {alternativeSubtitle}
                  </Typography>
                )}
              </>
            ) : (
              <Typography className={classes.actionSubtitle}>
                {subtitle}
              </Typography>
            )}
          </div>
        </div>
        <IconChevron className={classes.arrow} />
      </Card>
    </CardActionArea>
  );
};
