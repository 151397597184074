import React from 'react';
import {Typography} from '@mui/material';
import {blue500} from 'shared/styles/palette';

export const ExpectedRangeLabel = () => (
  <Typography
    sx={{
      color: blue500,
      fontSize: '13px',
      fontWeight: '700',
      lineHeight: '1.6',
      letterSpacing: '-0.039px',
    }}>
    Expected Range
  </Typography>
);
