import gql from 'graphql-tag';

export const CUSTOMER_PROFILES = gql`
  query Profiles {
    profiles {
      id
      name
      readingLevel
      gradeAndMonth
      profileCharacter
      requestedLevelAdjustmentAt
    }
  }
`;
