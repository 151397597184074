import React from 'react';
import {ReadingRateData} from '../types';
import {Link} from '../components/Link';
import AnalyticsService from 'analytics/Analytics.service';
import {normalizeOutlierWpm} from '../ProgressReportScreen.service';

type Props = {
  profileId: string;
  profileName: string;
  data: ReadingRateData;
  hasInsufficientReadingData: boolean;
};

export const ReadingRateCopy: React.FC<Props> = ({
  profileId,
  profileName,
  data,
  hasInsufficientReadingData,
}) => {
  const {lowerQuartileWpm, upperQuartileWpm, medianWpm} = data;
  const wpm = normalizeOutlierWpm(data);

  function trackEvent() {
    AnalyticsService.trackDashboardLevelAdjustmentClicked('Reading rate card');
  }

  if (hasInsufficientReadingData) {
    return (
      <>
        {profileName} hasn't read enough books recently to calculate their
        reading rate. Encourage them to read a few more books so we can assess
        if {profileName}'s on track.
      </>
    );
  }

  if (lowerQuartileWpm && wpm < lowerQuartileWpm) {
    return (
      <>
        At {profileName}'s reading level, we'd expect them to be reading between{' '}
        {lowerQuartileWpm}-{upperQuartileWpm} words per minute. Low reading
        rates can be caused by getting distracted while reading with Ello. But,
        if {profileName} can't complete a book without adult assistance,
        consider lowering their reading level{' '}
        <Link
          text={'here.'}
          onClick={trackEvent}
          to={`/account/profiles/${profileId}/adjustReadingLevel`}
        />
      </>
    );
  }

  if (medianWpm && wpm < medianWpm) {
    return (
      <>
        At {profileName}'s reading level, we'd expect them to be reading between{' '}
        {lowerQuartileWpm}-{upperQuartileWpm} words per minute. To help{' '}
        {profileName} improve their fluency even more, encourage them to re-read
        books they've read before with Ello.
      </>
    );
  }

  if (upperQuartileWpm && wpm <= upperQuartileWpm) {
    return (
      <>
        At {profileName}'s reading level, we'd expect them to be reading between{' '}
        {lowerQuartileWpm}-{upperQuartileWpm} words per minute. {profileName}'s
        reading rate is right on track, and re-reading their Ello books each
        week will help their reading rate increase even more!
      </>
    );
  }

  return (
    <>
      At {profileName}'s reading level, we'd expect them to be reading between{' '}
      {lowerQuartileWpm}-{upperQuartileWpm} words per minute. Encourage them to
      take their time reading clearly and accurately, and make sure they're not
      skipping pages. If {profileName}'s books seem too easy, consider adjusting
      their reading level{' '}
      <Link
        text={'here.'}
        onClick={trackEvent}
        to={`/account/profiles/${profileId}/adjustReadingLevel`}
      />
    </>
  );
};
