/*
 * Universal Ello Reset Password page.
 * Props:
 * - customTitle: optionally overwrite the rendered title
 * - customMessage: optionally overwrite the rendered message
 */

import Alert from '@mui/material/Alert';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import React, {useEffect, useState} from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import {Bar} from 'customerPortal/components/Banner';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'shared/utils/connectFirebase';
import {StringParam, withQueryParams} from 'use-query-params';
import RequestConfirmation from './ResetPasswordScreen.confirm-request';
import EmailTextField from './ResetPasswordScreen.email';
import ResetPassword from './ResetPasswordScreen.password';
import SuccessfulReset from './ResetPasswordScreen.reset-success';
import {PasswordResetStep} from './ResetPasswordScreen.service';
import styles from './ResetPasswordScreen.styles';

// We allow email to be passed in directly as a query param
const queryParams = {
  email: StringParam,
  mode: StringParam,
  oobCode: StringParam,
};

interface props extends WithStyles<typeof styles> {
  customTitle?: string;
  customMessage?: string;
  initialResetStep?: PasswordResetStep;
  query?: {
    email: string | undefined;
    mode: string | undefined;
    oobCode: string | undefined;
  };
}

const ResetPasswordScreen = ({
  customTitle,
  customMessage,
  classes,
  initialResetStep,
  query,
}: props) => {
  const [email, setEmail] = useState(query?.email || '');
  const [isLoading, setIsLoading] = useState(!!query?.email);
  const [resetStep, setResetStep] = useState(
    initialResetStep || PasswordResetStep.GetEmail,
  );
  const [error, setError] = useState('');
  const isGettingEmail = resetStep === PasswordResetStep.GetEmail;
  const isConfirmingRequest =
    resetStep === PasswordResetStep.ConfirmRequestSent;
  const isResettingPassword = resetStep === PasswordResetStep.ResetPassword;
  const isResetSuccessful =
    resetStep === PasswordResetStep.PasswordResetSuccess;

  useEffect(() => {
    window.analytics.page('Ello ResetPassword');
    if (query?.email) {
      resetPassword(query?.email);
    }
  }, [query?.email]);

  const resetPassword = (overwriteEmail?: string) => {
    if (!overwriteEmail) {
      return;
    }
    setIsLoading(true);

    firebase
      .auth()
      // make sure return URL points to login page
      .sendPasswordResetEmail(overwriteEmail, {url: window.location.origin})
      .then(result => {
        setIsLoading(false);
        setResetStep(PasswordResetStep.ConfirmRequestSent);
      })
      .catch(error => {
        console.error('Password Reset::', error);
        setIsLoading(false);
        setError(error.message);
      });
  };

  const handleErrorClose = (event?: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setError('');
  };

  return (
    <>
      <Bar isPublic />

      <Container
        component="main"
        className={classes.loginContainer}
        maxWidth="xs">
        {isLoading && (
          <CircularProgress
            color="primary"
            className={classes.loadingIndicator}
          />
        )}

        {!isLoading && (
          <>
            {isConfirmingRequest && <RequestConfirmation email={email} />}

            {isGettingEmail && (
              <EmailTextField
                customTitle={customTitle}
                customMessage={customMessage}
                email={email}
                setEmail={setEmail}
                resetPassword={resetPassword}
              />
            )}

            {isResettingPassword && (
              <ResetPassword
                setError={setError}
                resetMode={query?.mode}
                requestCode={query?.oobCode}
                setResetStep={setResetStep}
              />
            )}

            {isResetSuccessful && <SuccessfulReset />}
          </>
        )}

        <Snackbar
          open={!!error}
          onClose={handleErrorClose}
          autoHideDuration={3000}>
          <Alert onClose={handleErrorClose} severity="error">
            {error}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};

const StyledComponent = withStyles(styles)(ResetPasswordScreen) as any;

export default withQueryParams(queryParams, StyledComponent);
