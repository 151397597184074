import React from 'react';
import {ProgressCard} from '../components/ProgressCard/ProgressCard';
import {ReactComponent as ReadingRateIcon} from 'shared/assets/icons/ReadingRateIcon.svg';
import {SkeletonRows} from '../components/SkeletonRows';
import {BoxWhiskerContainer} from './BoxWhisker.container';
import {BoxWhisker} from './BoxWhisker';
import {Marker} from './Marker';
import {ReactComponent as MarkerIndex} from 'shared/assets/markerIndex.svg';
import {gray300, gray700} from 'shared/styles/palette';

type Props = {
  profileName: string;
};

export const ReadingRateEmpty: React.FC<Props> = ({profileName}) => {
  return (
    <ProgressCard
      icon={<ReadingRateIcon />}
      title={`${profileName}'s Reading Rate`}
      isEmpty>
      <BoxWhiskerContainer>
        <BoxWhisker sx={{backgroundColor: gray300}} />
        <Marker sx={{color: gray700}} value="?" marker={<MarkerIndex />} />
      </BoxWhiskerContainer>
      <SkeletonRows count={2} />
    </ProgressCard>
  );
};
