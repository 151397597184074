import React from 'react';
import {BoxWhiskerContainer} from './BoxWhisker.container';
import {ReactComponent as WhiskerIndex1} from 'shared/assets/whiskerIndex1.svg';
import {BoxWhisker} from './BoxWhisker';
import {Marker} from './Marker';
import {gray300, gray700} from 'shared/styles/palette';

export const BoxWhiskerEmpty = () => {
  return (
    <BoxWhiskerContainer>
      <BoxWhisker sx={{backgroundColor: gray300}} />
      <Marker sx={{color: gray700}} value="?" marker={<WhiskerIndex1 />} />
    </BoxWhiskerContainer>
  );
};
