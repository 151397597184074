import React from 'react';
import {CircularProgress, Button as MaterialButton} from '@mui/material';
import {SxProps, Theme} from '@mui/system';
import {turquoise900} from 'shared/styles/palette';

type Props = {
  label: string;
  onClick?: () => void;
  sx?: SxProps<Theme>;
  isLoading?: boolean;
};

export const Button: React.FC<Props> = ({label, onClick, sx, isLoading}) => {
  return (
    <MaterialButton
      variant="contained"
      disableElevation
      disableRipple
      disabled={isLoading}
      onClick={onClick}
      sx={{
        background: turquoise900,
        width: '100%',
        borderRadius: '50px',
        padding: '16px 80px 14px 80px',
        fontWeight: '700',
        letterSpacing: '-.048px',
        ...sx,
      }}>
      {isLoading ? <CircularProgress size={24} sx={{color: 'white'}} /> : label}
    </MaterialButton>
  );
};
