import createStyles from '@mui/styles/createStyles';
import * as palette from 'shared/styles/palette';
import {Theme} from '@mui/material/styles';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';

const useStyles = (theme: Theme) =>
  createStyles({
    content: {
      paddingTop: theme.spacing(1),
      minHeight: '70vh',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        paddingTop: theme.spacing(4),
        minHeight: 'auto',
      },
    },
    topContainer: {
      flex: 1,
    },
    body: {
      padding: theme.spacing(2),
    },
    icon: {
      height: 180,
      maxWidth: '80vw',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
    },
    button: {
      width: '100%',
    },
    backButton: {
      borderWidth: '1px',
      backgroundColor: palette.white,
      '&:hover, &:focus': {
        backgroundColor: palette.gray,
      },
    },
  });

export default useStyles;
